import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ProgressBar from "./ProgressBar";

class OnboardingContainer extends Component {
  render() {
    return (
        <main className={"content-page onboarding step-" + this.props.step}>
            <ProgressBar step={this.props.step} />
            <div className="content">
                <div className="wrapper-w900">
                    {this.props.children}
                </div>
            </div>
        </main>
    )
  }
}


export default OnboardingContainer;
import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import { Field } from "components/Forms"
import { reduxForm, SubmissionError } from 'redux-form';
import { requestPatchUser } from '../../modules/user/actions'
import {_required, _mobile} from "utils/validation"
import formatMobileNumber from "utils/formatMobileNumber"

let required = _required();
let mobile = _mobile();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class EnterMobile extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {
      results.user.mobileNumber = formatMobileNumber(results.user.mobileNumber);
      this.props.requestPatchUser({
        data: results.user,
        callback: (result)=>{
          if (!result.error) {
            this.props.history.push('/signup-confirm-mobile');
            return resolve();
          } else {
            return reject(new SubmissionError({
              user: {
                mobileNumber: 'Mobile number already signed up.'
              } 
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="signup-enter-mobile" title="Enter mobile" isLoading={this.props.submitting}>
        <p>To finish your registration please enter your mobile number.</p>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <Field name="user.mobileNumber" type="tel" title="Your mobile number" autoFocus validate={[required, mobile]} />
          <Navigation secondaryText="Back" secondaryLink="/signup" primaryText="Next" primaryLink="/signup-confirm-mobile" />
        </form>

      </ContainerBox>
    )
  }
}

EnterMobile = reduxForm({
  form: 'EnterMobile' // a unique name for this form
})(EnterMobile);

export default connect(mapStateToProps, mapDispatchToProps)(EnterMobile);
import React, { Component } from "react";
import NavButton from "components/NavButton";

class Constantina extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
    };

    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    return (
      <div className="constantina">
        <div className="constantina-title" onClick={()=>this.toggleShow()}>{this.props.title}</div>
        <div className="constantina-content" style={
          {
            pointerEvents: ((this.state.hidden) ? "none" : "all"),
            maxHeight: ((this.state.hidden) ? "0" : "1000px"),
            borderBottom: ((this.state.hidden) ? "none" : "1px solid #E2E2E2"),
          }}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Constantina;
import React, { Component } from "react";

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";
import PrivacyStatementContent from "../dashboard/components/PrivacyStatementContent";

class Step2 extends Component {
  render() {
    return (
      <OnboardingContainer step="2">
        <PrivacyStatementContent />

        <Navigation
          step={2}
          secondaryText="Back"
          secondaryLink="/buvidal/setup/step-1/"
          primaryText="I agree"
          primaryLink="/buvidal/setup/step-3"
        />
      </OnboardingContainer>
    );
  }
}

export default Step2;

import React, { Component } from "react";
import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import NavButton from "components/NavButton";

class SignupThanks extends Component {
    render() {
        return (
            <ContainerBox type="signup-thanks" title="Thank you">
                <p><strong className="blue">Thank you for registering.</strong></p>
                <p>You may now proceed to the Product Familiarisation Program (PFP)</p>
                <img src={require('../../img/accounts/signup_thanks.png')} alt="signup_thanks"/>
                <div className="navigation">
                    <div className="secondary">
                    </div>
                    <div className="primary">
                        <NavButton to="/buvidal/setup/step-1" className="button-type-1">Next</NavButton>
                    </div>
                </div>

            </ContainerBox>
        )
    }
}


export default SignupThanks;
export default (_phoneNumber)=>{
  let phoneNumber = _phoneNumber.replace(/\D/g,'');

  if (phoneNumber.substr(0, 3) === '614' && phoneNumber.length === 11) {
    phoneNumber = phoneNumber.replace('614', '04');
  } else if (phoneNumber.substr(0, 3) === '+614' && phoneNumber.length === 11) {
    phoneNumber = phoneNumber.replace('+614', '04');
  } else if (phoneNumber.substr(0, 1) === '4' && phoneNumber.length === 9) {
    phoneNumber = phoneNumber.replace('4', '04');
  }  
  return phoneNumber;
}
import React, { Component } from "react";
import styled from 'styled-components'
import Sidebar from "./components/Sidebar"
import PdfDocument from "./components/PdfDocument"
import { connect } from 'react-redux';

import HCPEducationBooklet from '../../../pdf/AU-BUV-2100040_Buvidal-HCP-educational-material.pdf'
import PatientEducationBooklet from '../../../pdf/AU-BUV-2100041_Buvidal-Patient-educational-material.pdf'
import WeeklyCMI from '../../../pdf/Buvidal Weekly CMI.pdf'
import WeeklyPI from '../../../pdf/Buvidal Weekly Product Information.pdf'
import PatientAlertCard from '../../../pdf/AU-BUV-210004_Buvidal-Patient-Alert-Card.pdf'
import MonthlyCMI from '../../../pdf/Buvidal_Monthly_CMI.pdf'
import MonthlyPI from '../../../pdf/Buvidal_Monthly_Product_Information.pdf'
import QUMSummary from '../../../pdf/HCP_Guide_DIG_LAYOUT.pdf'
import PatientConsent from 'pdf/Patient_consent_form.pdf'

import createRegistrationLink from "utils/createRegistrationLink";

import isStaging from "utils/isStaging";

export const DocumentName = styled.div`
    font-size: 16px;
    font-family: "Helvetica Bold",Helvetica,Arial,sans-serif;
    font-weight: 600;
    color: #7E7E7E;
`
function mapStateToProps(state) {
    return {
      user: state.user,
    }
  }
class Resources extends Component {
    
    render() {

        const queryString = createRegistrationLink(this.props.user)

        return (
            <main className={"dashboard content-page " + this.props.type}>
                <Sidebar />
                <div className="content">
                  <h1>Resources</h1>
                  <p>To access up to date information and resources, visit <a target='_blank' rel="noopener noreferrer" href={`https://${isStaging() ? 'staging.' : ''}buvidal.com.au`}>Buvidal.com.au</a></p>
                  <p>Have you registered to access the Buvidal<sup>®</sup> site for healthcare professionals? <a target='_blank' rel="noopener noreferrer" href={`https://${isStaging() ? 'staging.' : ''}buvidal.com.au/account/sign-up/?${queryString}`}>Click here</a> to complete registration, or to visit the site.</p>
                  <PdfDocument documentName="HCP education booklet" link={HCPEducationBooklet} />
                  <PdfDocument documentName="Patient education booklet" link= {PatientEducationBooklet} />
                  <PdfDocument documentName={<DocumentName>Buvidal<sup>®</sup> Weekly Consumer Medicines Information (CMI)</DocumentName>} link={WeeklyCMI} />
                  <PdfDocument documentName={<DocumentName>Buvidal<sup>®</sup> Weekly Product Information</DocumentName>} link={WeeklyPI} />
                  <PdfDocument documentName={<DocumentName>Buvidal<sup>®</sup> Monthly Consumer Medicines Information (CMI)</DocumentName>} link={MonthlyCMI} />
                  <PdfDocument documentName={<DocumentName>Buvidal<sup>®</sup> Monthly Product Information</DocumentName>} link={MonthlyPI} />
                  <PdfDocument documentName="Patient Alert Card" link={PatientAlertCard} />
                  <PdfDocument documentName="Patient consent form" link={PatientConsent}/>
                  <PdfDocument documentName='PFP HCP Guide' link={QUMSummary} />
                  <PdfDocument documentName='Quality Use of Medicines Summary' onClick={() => this.props.history.replace({pathname: "/buvidal/summary"})} />
                </div>
            </main>
        )
    }
}

export default connect(mapStateToProps)(Resources);
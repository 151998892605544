import React, { Component } from "react";
import { Field as ReduxField } from 'redux-form';

class PinInputComponent extends Component {

    componentWillMount() {
    this.setState(state=>({...state, timer: 0, value: ''}));
    }

    // componentDidMount() {
    //     let props = this.props;
    //     var that = this;
        
    //     ;(function(){
    //         console.log('test');
    //         var fixedEvent = 0;
    //         /* fix a bug in Chrome where 'keypress' isn't fired for "non-visisble" keys */
        
    //         function funnel(e){
    //             // some pre-validation using HTML5 pattern attribute to allow only digits
    //             if( e.charCode && this.pattern ){
    //                 e.preventDefault();
    //                 var regex = this.pattern,
    //                 char = String.fromCharCode(e.charCode),
    //                 valid = new RegExp("^"+regex+"$").test( char );
    //                 console.log(valid);
    //                 if( !valid ) return false;
    //             }
            
    //             fixedEvent++;
    //             var that = this;
    //             setTimeout(function(){
    //                 keypress.call(that, e);
    //                 fixedEvent = 0;
    //             },0);
    //         }
        
    //         function keypress(e){
    //             var nextPrevField,
    //                 sel = [this.selectionStart, this.selectionEnd];
            
    //             if( !e.charCode && e.keyCode !== 37 && e.keyCode !== 39 && e.keyCode !== 8 && e.keyCode !== 13)
    //                 return;	

    //             else if(e.keyCode === 13) 
    //                 that.props.onSubmit();
                    
    //             // if hit Backspace key when caret was at the beginning, or if the 'left' arrow key was pressed and the caret was at the start -> go back to previous field	
    //             else if( (e.keyCode === 8 && sel[1] === 0) || (e.keyCode === 37 && sel[1] === 0) )
    //                 setCaret( window.$(this).prev(':text')[0], 100);
                
    //             // if the 'right' arrow key was pressed and caret was at the end -> advance to the next field
    //             else if( e.keyCode === 39 && sel[1] === this.value.length )	
    //                 setCaret( window.$(this).next(':text')[0], 0);
                
    //             // automatically move to the next field once user has filled the current one completely		
    //             else if( e.charCode ) {
    //                 window.$(this).val(e.charCode - 48);
    //                 setCaret( window.$(this).next(':text')[0], 100);
    //             }

    //             function setCaret(input, pos){
    //                 if( !input ) return;
    //                 if (input.setSelectionRange){
    //                     input.focus();
    //                     input.setSelectionRange(pos, pos);
    //                 }
    //                 else if( input.createTextRange ){
    //                     var range = input.createTextRange();
    //                     range.collapse(true);
    //                     range.moveEnd('character', pos);
    //                     range.moveStart('character', pos);
    //                     range.select();
    //                 }
    //             }
                
    //             combine.apply(this);
    //         };
    //         // After each 'change' event of any of the fields, combine all the values to the hidden input.	
    //         function combine(){
    //             let value = "";
    //             window.$(this.parentNode).find('input:not(:hidden)').each( function(){			
    //                 value += this.value;
    //             });
    //             props.input.onChange(value);
    //         }

    //         window.$('div.multifield').on({'keydown.multifeild':funnel,'keypress.multifeild':funnel, }, 'input');
    //     })();

    // }

    resend() {
        this.props.resendCode();
        let countDown = ()=>{
            setTimeout(() => {
                if (this.state.timer > 1) {
                    countDown();
                }
                this.setState(state=>({...state, timer: state.timer - 1}));
            }, 1000);
        }
        setTimeout(() => {
            this.setState(state=>({...state, timer: 15}));
            countDown();
        }, 1000);
    }

    render() {
        console.log(this.props);
        return (
            <div className={"email-code multifield" + ((this.props.meta.error)?" error ":" " + (this.props.type || ''))}>
                <div className="digits">
                    {/* <input type="text" maxLength="1" inputMode='numeric' pattern="\d+" autoFocus />
                    {[...Array(this.props.length - 1).keys()].map((key)=>
                        <input type="text" maxLength="1" inputMode='numeric' pattern="\d+" key={key} />)} */}
                    <input 
                        type='text' 
                        maxLength={this.props.length} 
                        value={this.props.input.value} 
                        onChange={e => this.props.input.onChange(e.target.value)} 
                        inputMode='numeric' 
                        pattern="\d+" 
                        autoFocus
                    />
                </div>
                
                {(this.props.meta.error)?
                <span className="error-text">{this.props.meta.error}</span>
                :""}              
                { this.state.timer > 0 ? (
                    <div className="resend">Code resent. Please wait {this.state.timer}s to resend code.</div>
                ):(
                    <div className="resend" onClick={()=>this.resend()}>Didn't recieve the code? <a className="blue">Resend code.</a></div>
                )}
            </div>
        );
    }
}

export default (props) => <ReduxField {...props} component={PinInputComponent} />;
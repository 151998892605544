import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import { NavLink } from "react-router-dom";
import Sidebar from "./components/Sidebar"
import { Checkbox } from "components/Forms"
import Navigation from "./components/Navigation";
import { requestPatchUser, requestLogout } from 'modules/user/actions'

const requireYes = value => value === 'yes' ? undefined : `You must select 'Yes' if you wish to deregister.`;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    requestLogout
  }, dispatch);
}

class DeRegisterProfile extends Component {
  submit(_results) {
    return new Promise((resolve, reject) => {
      this.props.requestPatchUser({
        data: {
          signupStage: -1
        },
        callback: (result) => {
          this.props.requestLogout(() => {
          });
        }
      });
    });
  }

  render() {

    const discontinue = [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ];

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
            <h1>De-register profile</h1>
            <p>Once you confirm your discontinuation from the PFP your profile will be deactivated and you will exit the website.</p>
            <Checkbox radio validate={[requireYes]} styling="float" name="user.registration" title="Please confirm that you wish to discontinue your participation in the PFP" options={discontinue} />

            <Navigation secondaryText="Cancel" secondaryLink="/buvidal/settings/" primaryText="De-register" />
          </form>
        </div>
      </main>
    )
  }
}

DeRegisterProfile = reduxForm({
  form: 'deregister-profile' // a unique name for this form
})(DeRegisterProfile);

export default connect(mapStateToProps, mapDispatchToProps)(DeRegisterProfile);
import React, { Component } from "react";
import Sidebar from "./components/Sidebar"
import { NavLink } from "react-router-dom";

const cams = [
    {
        state: 'ACT/NSW',
        name: 'Christos Romanas',
        phone: '0428 474 868',
        email: 'christos.romanas@camurus.com'
    },
    {
        state: 'NSW',
        name: 'Lauren Cassidy',
        phone: '0426 081 183',
        email: 'lauren.cassidy@camurus.com'
    },
    {
        state: 'QLD',
        name: 'Emma Winks',
        phone: '0476 003 138',
        email: 'emma.winks@camurus.com'
    },
    {
        state: 'VIC',
        name: 'Carolanne Easy',
        phone: '0414 857 505',
        email: 'carolanne.easy@camurus.com'
    },
    {
        name: 'Nadine Francis',
        phone: '0400 840 236',
        email: 'nadine.francis@camurus.com'
    },
    {
        state: 'NT/SA/TAS/WA',
        name: 'Ruari Macdonald',
        phone: '0418 760 988',
        email: 'ruari.macdonald@camurus.com '
    },
]

class ContactUs extends Component {
    render() {
        return (
            <main className={"dashboard content-page " + this.props.type}>
                <Sidebar />
                <div className="content">
                    <h1>Contact us</h1>

                    <h2>PFP Administrator</h2>
                    <p><strong>Monday-Friday 9am – 5 pm (AEST)</strong><br/>
                    <strong className="light">P:</strong> 1300 862 994<br/> 
                    <strong className="light">E:</strong> <a href="mailto:pfpadmin@nof1solutions.com.au">pfpadmin@nof1solutions.com.au</a></p>

                    <h2>CAMURUS</h2>
                    <h3>Clinical Account Managers</h3>
                    {cams.map((cam) => (
                        <p>{cam.state && <><strong>{cam.state}</strong><br /></>}
                        <div className='light'>{cam.name}</div>
                        <strong className="light">P:</strong> <a href={`tel: ${cam.phone}`}>{cam.phone}</a><br />
                        <strong className="light">E:</strong> <a href={`mailto:${cam.email}`}>{cam.email}</a></p>
                    ))}

                    <h3>Medical Support</h3>
                    <p>
                        <strong className="light">P:</strong> <a href={`tel:1800 142 038`}>1800 142 038</a><br />
                        <strong className="light">E:</strong> <a href={`mailto:ausmedinfo@camurus.com`}>ausmedinfo@camurus.com</a>
                    </p>

                    <h3>Adverse Event Reporting</h3>
                    <p>Reporting suspected adverse events after registration of a medicine is important. It allows continued monitoring of the benefit-risk balance. Healthcare professionals are expected to report any suspected adverse events to:</p>
                    <p>
                        Camurus Medical Information <br />
                        <strong className="light">P:</strong> <a href={`tel:1800 142 038`}>1800 142 038</a><br />
                        <strong className="light">E:</strong> <a href={`mailto:ausmedinfo@camurus.com`}>ausmedinfo@camurus.com</a> 
                    </p>
                    <p>
                        Camurus Drug Safety <br />
                        <strong className="light">P:</strong> <a href={`tel:1800 142 038`}>1800 142 038</a><br />
                        <strong className="light">E:</strong> <a href={`mailto:safety@camurus.com`}>safety@camurus.com</a> 
                    </p>
                    <p>
                        Product complaints <br />
                        <strong className="light">E:</strong> <a href={`mailto:complaints@camurus.com`}>complaints@camurus.com</a> 
                    </p>
                </div>
            </main>
        )
    }
}

export default ContactUs;
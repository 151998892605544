import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import Sidebar from "../components/Sidebar"
import { boolean } from "utils/selectValues";
import { Checkbox } from "components/Forms"
import Warning from "../components/Warning";

import { PatientDetailBox } from "../components/DetailBox"
import getQueryParams from "utils/getQueryParams";
import { requestPatchUser } from 'modules/user/actions';
import Navigation from "../components/Navigation";

import {_required} from "utils/validation"
let required = _required();
const requireYes = value => value !== 'false' ? undefined : 'You must select "Yes" to discontinue patient.';

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient=>patient.id == id)[0];
  } else {
    patient = null;
  }

  return {
    patient,
    confirmDiscontinue: state.form.DiscontinuePatient && state.form.DiscontinuePatient.values && state.form.DiscontinuePatient.values.confirmDiscontinue
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class DiscontinuePatient extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      this.props.requestPatchUser({
        data: {
          patient: {
            id: this.props.patient.id,
            state: 'discontinued'
          }
        },
        callback: (result) => {
          if (!result.error) {
            this.props.history.push('/buvidal/patients/');
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {

    if (!this.props.patient) {
      return "No patient selected";
    }

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
            <h1>Discontinue patient</h1>
            <h2>For patient</h2>
            <PatientDetailBox patient={this.props.patient} />

            <h2>Adverse event reporting</h2>
            <p>Please be advised, you are expected to report suspected adverse events and other safety findings including overdose, misuse, medication error, loss or lack of effect, pregnancy and lactation exposure, and any unexpected therapeutic benefit to Camurus Drug Safety by phone on 1800 142 038 or email at <a href="mailto:safety@camurus.com">safety@camurus.com</a>.</p>
            <p>You are also encouraged to report any adverse events during the Buvidal PFP to the Therapeutics Goods Administration (TGA) at <a href="mailto:www.tga.gov.au/reporting-problems">www.tga.gov.au/reporting-problems</a>.</p>
            <p>Product complaints are to be reported to <a href="mailto:complaints@camurus.com">complaints@camurus.com</a>.</p>

            <h2>Confirmation</h2>
            <Checkbox validate={[required, requireYes]} radio title="Please confirm that you wish to discontinue this patient from the Buvidal PFP" styling="float r" name="confirmDiscontinue" options={boolean} />

            {this.props.confirmDiscontinue === 'true' &&
              <Warning>
                <p>Any patients discontinued from the PFP will no longer receive drug supply.</p>
              </Warning>
            }

            <Navigation secondaryText="Back" secondaryLink={"/buvidal/patients"} primaryText="Confirm" primaryLink="" />
          </form>
        </div>
      </main>
    )
  }
}

DiscontinuePatient = reduxForm({
  form: 'DiscontinuePatient' // a unique name for this form
})(DiscontinuePatient);

export default connect(mapStateToProps, mapDispatchToProps)(DiscontinuePatient);
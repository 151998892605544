import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import PinInput from "./components/PinInput";
import { reduxForm, SubmissionError } from 'redux-form';
import { requestValidationMessage } from '../../modules/user/actions'
import { requestVerification } from '../../modules/user/actions'

function mapStateToProps(state) {
  return {
    email: state.user.email,
    mobileNumber: state.user.mobileNumber
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestValidationMessage,
    requestVerification,
  }, dispatch);
}


class ConfirmMobile extends Component {

  componentWillMount() {
    this.setState(state=>({...state, loading: false}));
  }

  submit(results) {
    console.log(JSON.stringify(results, null, 2));
    return new Promise((resolve, reject) => {
      if (!results.pin || results.pin.length < 4) {
        return reject(new SubmissionError({
          'pin': 'Please fill in all numbers.'
        }));
      }

      this.props.requestVerification({
        email: this.props.email,
        token: results.pin,
        type: 'mobile',
        callback: (result) => {
          if (!result.err) {
            this.props.history.push('/signup-complete-account');
            return resolve();
          } else {
            if (result.err.message === 'PIN Limit Exceeded') {
              this.props.history.push(`/too-many-pin-attempts`);
            }
            return reject(new SubmissionError({
              pin: result.err.message
            }));
          }
        }
      });
    });
  }

  resendCode() {
    this.setState(state=>({...state, loading: true}));
    let isWaiting = true;
    setTimeout(() => {
      if (!isWaiting) {
        this.setState(state=>({...state, loading: false}));
      } else {
        isWaiting = false;
      }
    }, 1000);
    this.props.requestValidationMessage({
      email: this.props.email,
      mobileNumber: this.props.mobileNumber,
      callback: (result) => {
        if (result.err && result.err.message === 'SMS Limit Exceeded') {
          this.props.history.push(`/too-many-sms-attempts`);
        } else {
          if (!isWaiting) {
            this.setState(state=>({...state, loading: false}));
          } else {
            isWaiting = false;
          }
        }
      }
    });
  }

  render() {
    return (
      <ContainerBox type="signup-confirm-mobile" title="Confirm mobile" isLoading={this.props.submitting || this.state.loading}>
        <p><strong className="blue">{this.props.mobileNumber}</strong></p>
        <p>A message with a verification code was sent to your mobile phone. Please enter the code below.</p>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <PinInput name="pin" type='mobile' length={4} resendCode={() => this.resendCode()} onSubmit={this.props.handleSubmit(results => this.submit(results))} />
          <Navigation secondaryText="Back" secondaryLink="/signup-enter-mobile" primaryText="Next" primaryLink="/signup-complete-account" />
        </form>

      </ContainerBox>
    )
  }
}

ConfirmMobile = reduxForm({
  form: 'ConfirmMobile' // a unique name for this form
})(ConfirmMobile);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmMobile);
import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';

import { requestForgotPassword } from '../../modules/user/actions'

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import { Field } from "components/Forms"
import formatMobileNumber from "utils/formatMobileNumber"
import {_required} from "utils/validation"

let required = _required();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestForgotPassword,
  }, dispatch);
}


class ForgotPassword extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      let data = {};

      if (results.username.indexOf('@') !== -1) {
        data.email = results.username;
      } else {
        data.mobileNumber = formatMobileNumber(results.username);
      }

      this.props.requestForgotPassword({
        data,
        callback: (result)=>{
          if (!result.err) {
            if (data.email) {
              this.props.history.push('/forgot-password-confirm-pin?email=' + data.email);
            } else {
              this.props.history.push('/forgot-password-confirm-pin?mobile=' + data.mobileNumber);
            }
            return resolve();
          } else {
            if (result.err.message === 'SMS Limit Exceeded') {
              this.props.history.push(`/too-many-sms-attempts`);
              return reject(new SubmissionError({
                username: 'SMS Limit Exceeded.'
              }));
            } else {
              return reject(new SubmissionError({
                username: 'Email or mobile number doesn\'t exist.'
              }));
            }
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="forgot-password" title="Reset password" isLoading={this.props.submitting}>
        <p>Enter your email address or mobile number to reset your password.</p>

        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <Field name="username" type="text" title="Enter your email or phone number" autoFocus validate={[required]} />
          <Navigation secondaryText="Back to Log In" secondaryLink="/login" primaryText="Next" primaryLink="/forgot-password-confirm-mobile" />
        </form>
      </ContainerBox>
    )
  }
}


ForgotPassword = reduxForm({
  form: 'ForgotPassword' // a unique name for this form
})(ForgotPassword);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { Field, Select, Checkbox, UnsavedChangesPrompt } from "components/Forms"
import { trueFalse, gender, treatmentStablised, drugAddiction, durationOfInitialTreatment, patientStatus, previousTreatment } from "utils/selectValues";
import moment from 'moment';
import Warning from "../components/Warning";
import { Link } from "react-router-dom";

import Sidebar from "../components/Sidebar"
import Navigation from "../components/Navigation";
import PatientProgressBar from "../components/PatientProgressBar";
import { requestPatchUser } from 'modules/user/actions'

import PdfDocument from "../components/PdfDocument"
import PatientConsent from "pdf/Patient_consent_form.pdf";
import filterInitialValues from 'utils/filterInitialValues'
import getQueryParams from "utils/getQueryParams";

import {_required, _email, _mobile} from "utils/validation"
let required = _required();
let email = _email();
let mobile = _mobile();
let requiredDate = _required("Please enter a valid date in the format: DD/MM/YYYY.");
let dobValidation = value => {
  let diff = moment().diff(moment(value), 'years');
  if (diff < 16) {
    return "Patient must be 16 years of age or older."
  }
}
const requireYes = value => value !== 'false' ? undefined : 'Patient cannot be enrolled if requirement not met.';
let durationOfInitialTreatmentValidation = value => value !== "Days: Less than 7" ? undefined : 'Initial treatment duration must be at least 7 days.';

let requiredGroup = (value, allValues) => {
  if (!allValues.user) return undefined;

  var emailExists = (allValues.user.patient.email !== undefined && allValues.user.patient.email !== '');
  var mobileNumberExists = (allValues.user.patient.mobileNumber !== undefined && allValues.user.patient.mobileNumber !== '');
  var resourcesCheckedExists = !(!allValues.user.patient.resourcesCheck || allValues.user.patient.resourcesCheck.length === 0);

  if (emailExists || mobileNumberExists || resourcesCheckedExists) {
    return undefined;
  }

  return 'This field is required.';
}


function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient=>patient.id == id)[0];
  } else {
    patient = null;
  }

  let otherInitialValues = {};
  if (patient) {
    otherInitialValues = {
      olderThan16: 'true',
      opiodDependence: 'true',
      stablised: 'true', 
      contraindications: 'true',
      patientReviewed: 'true',
    }
  }

  return {
    patient,
    user: state.user,
    PatientEnrolForm: state.form.PatientEnrol,
    initialValues: {
      user: {
        patient: {
          ...patient,
          treatmentSiteId: (patient && patient.treatmentSiteId) || state.user.buvidal.treatmentSite
        },
      },
      ...otherInitialValues
    },
    fields: (state.form.PatientEnrol && state.form.PatientEnrol.registeredFields) || {},
    treatmentSites: state.user.treatmentSites ? state.user.treatmentSites.map(site=>({value:site.id, label:site.name})) : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class PatientEnrol extends Component {

  scrollToError() {
    setTimeout(() => {
      if (window.$('.error').length) {
        window.$('html, body').animate({
            scrollTop: window.$('.error').offset().top - 150
        }, 200);
      }
    }, 10);
  }


  submit(_results) {
    return new Promise((resolve, reject) => {
      _results.user.patient.initials = _results.user.patient.initials && _results.user.patient.initials.toUpperCase();
      _results.user.patient.dob = moment(_results.user.patient.dob).format('YYYY-MM-DD');

      let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);

      if (!results.user.patient || results.user.patient.length === 0) {
        this.props.history.push({
          pathname: "/buvidal/patients/onboarding-drug-order/?patientId=" + this.props.patient.id,
          data: this.props.patient.id
        });
      }

      let materialProvidedBy = [];
      if (_results.user.patient.email) materialProvidedBy.push('email');
      if (_results.user.patient.mobileNumber) materialProvidedBy.push('sms');
      if (_results.user.patient.resourcesCheck) materialProvidedBy.push('hardcopies');

      results.user.patient.materialProvidedBy = materialProvidedBy.join(', ');

      results.user.patient.id = (this.props.patient)?this.props.patient.id:"create";
      results.user.patient.state = "active";
      results.user.patient.treatmentSiteId = _results.user.patient.treatmentSiteId;

      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push({
              pathname: "/buvidal/patients/onboarding-drug-order/"
            });
            return resolve();
          } else {
            this.scrollToError();
            return reject(new SubmissionError(result.err.data.validation));
          }
        }
      });
    });
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      this.scrollToError();
    }
    return false;
  }

  render() {
    let activePatients = this.props.user.patients

    if((this.props.user.orders.length / 2) <= activePatients.length) {
      this.props.history.push({
        pathname: "/buvidal/patients/onboarding-drug-order/?orderFirst=true"
      });
    }

    const authorisationHardCopies = [
      { value: 'true', label: 'I have provided the patient with hardcopies or soft copies of the education resources.' },
    ];

    const authorisationReview = [
      { value: 'true', label: 'I confirm that I have reviewed the Patient Education Material with the patient, and that the patient has provided informed consent and that I have completed any additional requirements relevant for my individual state and/or institution requirements as relevant.' },
    ];

    if (this.props.patient && this.props.patient.shipmentDate && this.props.patient.orderConfirmedDate === this.props.patient.shipmentDate) {
      return (
        <main className={"dashboard content-page " + this.props.type}>
          <Sidebar />
          <div className="content">
            <h2>Your order has been submitted</h2>
          </div>
        </main>
      );
    }

    const {patient} = this.props.PatientEnrolForm && this.props.PatientEnrolForm.values && this.props.PatientEnrolForm.values.user ? this.props.PatientEnrolForm.values.user : {}
    const {drugAddiction: drugAddiction_value} = patient || []
    const showOtherAddicition = drugAddiction_value && drugAddiction_value.includes('other')
    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        {/* <PatientProgressBar step="1" /> */}

        <div className="content">
          <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
            <input hidden autoComplete="off" />
            <h1>Enrol new patient</h1>
            <p>Please review the full Product Information (PI) for both Buvidal<sup>®</sup> Weekly and Buvidal<sup>®</sup> Monthly before prescribing. Full PIs, the program guide, TGA-approved program guide, TGA-approved educational resources for healthcare professionals and patients, Product Information and Consumer Medicines Information for Buvidal<sup>®</sup> products, Patient Alert Card, and patient consent form can be accessed at any time through the <Link to='/buvidal/resources'>Resources tab</Link>.</p>
            
            <h2>Patient eligibility criteria</h2>
            <p>Please confirm that this patient meets the following criteria:</p>

            <Checkbox radio validate={[required, requireYes]} title="Patient is 16 years of age or older" styling="float" name="olderThan16" options={trueFalse} />
            <Checkbox radio validate={[required, requireYes]} title="Patient has been diagnosed with opioid dependence, but is not currently treated with pharmacotherapy for opioid dependence." styling="float" name="opiodDependence" options={trueFalse} />
            <Checkbox radio validate={[required, requireYes]} title={<>Patient will not be administered Buvidal<sup>®</sup> treatment until objective and clear signs of mild to moderate withdrawal are evident. For patients using heroin or short-acting opioids, the initial dose of Buvidal<sup>®</sup> will not be administered until at least 6 hours after the patient last used opioids.</>} styling="float" name="stablised" options={trueFalse} />
            <Checkbox radio validate={[required, requireYes]} title={<span>Patient does not have any contraindications (see <Link to='/buvidal/resources'>Product Information</Link>)</span>} styling="float" name="contraindications" options={trueFalse} />

            <h2>Patient details</h2>
            <Select validate={[required]} disabled styling="float l" name="user.patient.treatmentSiteId" type="text" title="Treatment site" options={this.props.treatmentSites} />
            <Field validate={[required]} styling="float r" name="user.patient.initials" type="text" title="Initials" maxlength={4} />
            <Select validate={[requiredDate, dobValidation]} date disableCalendar isClearable styling="float l" name="user.patient.dob" type="text" title="Date of birth (DD/MM/YYYY)" noOptionsMessage={() => null} />
            <Select validate={[required]} styling="float r" name="user.patient.sex" type="text" title="Sex" options={gender} />
            <Select validate={[required]} isMulti styling="float l" name="user.patient.drugAddiction" type="text" title="Primary drug of addiction" options={drugAddiction} />
            {showOtherAddicition && <Field validate={[required]} styling="float r" name="user.patient.drugAddiction_other" type="text" title="Other primary drug of addiction" />}
            <Select validate={[required]} styling={`float ${showOtherAddicition ? 'l' : 'r'}`} name="user.patient.status" type="text" title="Patient status" options={patientStatus} />
            {patient && patient.status === 'Returning to treatment' && <Select validate={[required]} styling="float r" name="user.patient.previousTreatment" type="text" title="Previous treatment" options={previousTreatment} />}
            <p>Before commencing Buvidal<sup>®</sup> it is recommended to obtain baseline liver function tests and to document viral hepatitis status. Patients who are positive for viral hepatitis, taking other medicines and/or have existing liver dysfunction are at greater risk of liver injury. Regular monitoring of the liver function is recommended.</p>

            {!(this.props.patient && this.props.patient.id) && 
              <div>
                <h2>Patient resources</h2>
                <p>If you have not already done so, please provide your patient with access to:</p>
                <ul>
                  <li>Buvidal<sup>®</sup> Weekly and Buvidal<sup>®</sup> Monthly Consumer Medicines Information (CMIs)</li>
                  <li>TGA-approved patient education material</li>
                  <li>Patient alert card</li>
                </ul>
                <br />
                <p>You may enter the patient’s details below to send a link to the resources via email or SMS. (Note: These details will not be saved.)</p>
                <br />
                {/* <Warning>
                  <p>Your patients details will not be stored or collected by the PFP administrator via the website or any other device.</p>
                </Warning>
                <br /> */}

                <Field validate={[email]} styling="float l" name="user.patient.email" type="email" title="Patient email address" />
                <Field validate={[mobile]} styling="float r" name="user.patient.mobileNumber" type="tel" title="Patient mobile number" />
                <p>Alternatively you may <NavLink to="/buvidal-patient-resources" target="_blank">print the resources</NavLink> for the patient.</p>
                <Checkbox styling="float single" name="user.patient.resourcesCheck" options={authorisationHardCopies} validate={[requiredGroup]} />

                <h2>Patient consent</h2>
                <PdfDocument last documentName="Patient consent form" link={PatientConsent} />
                <ol>
                  <li><a rel="noopener noreferrer" target="_blank" href={PatientConsent}>Print</a> the Patient Consent form.</li>
                  <li>Ask your patient to sign</li>
                  <li>Provide your patient them with a copy and retain the signed form in your patient’s records.</li>
                </ol>
              </div>
            }

            <Checkbox validate={[required]} styling="float single" name="patientReviewed" options={authorisationReview} />
            <Navigation
              primaryText="Enrol patient"
              primaryLink="/buvidal/patients/onboarding-drug-order"
            />
            <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting)} />
          </form>
        </div>
      </main>
    )
  }
}

PatientEnrol = reduxForm({
  form: 'PatientEnrol' // a unique name for this form
})(PatientEnrol);

export default connect(mapStateToProps, mapDispatchToProps)(PatientEnrol);

import { combineReducers } from 'redux';
import feathers from '@feathersjs/client';
import { fork, all } from 'redux-saga/effects';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { user } from './user/reducers';
import * as userSagas from './user/sagas';
import { modalsReducer } from './modals/reducers';
import 'whatwg-fetch';

//import { pageReducer } from './page/reducers';

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  modals: modalsReducer,
  user,
  form: formReducer,
  router: connectRouter(history),
  //page: pageReducer,
  //router: routerReducer,
});

export function* rootSaga() {
  yield all([
    ...Object.values(userSagas),
  ].map(fork));
}

let host = "";

if (process.env.NODE_ENV === 'development') {
  host = 'https://api-stag.medax.net.au';
  //host = 'http://localhost:3030';
} else {
  if (window.location.hostname.indexOf('stag') !== -1) {
    host = 'https://api-stag.medax.net.au';
  } else if (window.location.hostname.indexOf('training') !== -1) {
    host = 'https://api-training.medax.net.au';
  } else {
    host = 'https://api.medax.net.au';
  }
}

export const app = feathers()
  .configure(feathers.rest(host).fetch(window.fetch))
  .configure(feathers.authentication({
    storage: window.localStorage
  }));

export const usersService = app.service('users');
export const verificationService = app.service('verification');
export const emailService = app.service('email');
export const hostUrl = host;
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, change } from 'redux-form';
import { Field, Select, Checkbox, UnsavedChangesPrompt } from "components/Forms"
import moment from 'moment-timezone'
import { weeklyDrugs, monthlyDrugs } from "utils/selectValues";
import styled from "styled-components";
import Box from "new-components/Education/Box";

import Navigation from "../../components/Navigation";
import Constantina from "../../components/Constantina";

import { PatientDetailBox } from "../../components/DetailBox"
import getQueryParams from "utils/getQueryParams";
import { requestPatchUser } from 'modules/user/actions'
import filterInitialValues from 'utils/filterInitialValues'

import getCurrentDate from "utils/getCurrentDate"
import deliveryTimes from "utils/deliveryTimes"
import OrderCycleImg from '../../../../../img/product/order_cycle.png'

import { _required, _email } from "utils/validation"
let required = _required();
let requiredDate = _required("Please enter a valid date in the format: DD/MM/YY.");

const dateFormatString = 'ddd Do MMMM YYYY';

let administrationDateValidation = (value, allValues) => {
  
  let shipmentDate = moment(allValues.user.patient.shipmentDate, dateFormatString);
  let currentDate = getCurrentDate().tz('Australia/Sydney');
  console.log(currentDate.hour());
  if (currentDate.hour() >= 12) {
    currentDate.add(1, 'd');
  }
  if (shipmentDate.isBefore(currentDate, 'day')) {
    return "Cannot ship in time for given administration date";
  } else {
    return undefined;
  }
};

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient, treatmentSite;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient => patient.id == id)[0];
    treatmentSite = (patient && patient.treatmentSiteId && state.user.treatmentSites.find(site => site.id === patient.treatmentSiteId)) || null;
  } else {
    patient = treatmentSite = null;
  }

  return {
    patient,
    patients: state.user.patients,
    user: state.user,
    orders: state.user.orders ? state.user.orders : [],
    initialValues: {
      user: {
        patient
      }
    },
    include8mg: treatmentSite && treatmentSite.allowAdditional,
    isRural: treatmentSite && treatmentSite.delivery === 'rural',
    fields: (state.form.DrugOrderDetails && state.form.DrugOrderDetails.registeredFields) || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    change
  }, dispatch);
}

const Col = styled.div`
  width: calc(50% - 10px);
  display: inline-block;
  flex-direction: column;

  img {
    width: 100%;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 550px) {
    display: block;
  }
`
class DrugOrderDetails extends Component {

  submit(_results) {
    return new Promise((resolve, reject) => {
      let fields = { ...this.props.fields };
      let results = filterInitialValues(_results, fields, this.props.initialValues);

      let params = getQueryParams()

      let redirectPath = '/buvidal/patients'
      if(params.orderFirst) {
        redirectPath = '/buvidal/patients/onboarding-enrol-patient'
      }

      let order = {
        order: [
          {
            hcpId: this.props.user.id,
            patientId: null,
            treatmentSiteId: this.props.user.buvidal.treatmentSite,
            shipmentDate: null,
            orderDose: 8,
            qty: 2,
            orderDateTime: moment().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:SS'),
            orderType: 'regular',
          },
          {
            hcpId: this.props.user.id,
            patientId: null,
            treatmentSiteId: this.props.user.buvidal.treatmentSite,
            shipmentDate: null,
            orderDose: 16,
            qty: 1,
            orderDateTime: moment().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:SS'),
            orderType: 'regular',
          }
        ]
      }

      this.props.requestPatchUser({
        data: order,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push({
              pathname: redirectPath,
              // data: {
              //   patientId: result.id
              // }
            });
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  setShipmentDate(administrationDate) {

    let adminDate = moment(administrationDate.value || administrationDate);
    let oldAdminDate = moment(this.props.patient.administrationDate);

    if (this.props.patient) {
      let currentShipmentDate = moment(this.props.patient.shipmentDate);
      if (oldAdminDate.isBefore(currentShipmentDate, 'd')) {
        if (this.props.patient.oldDose ? this.props.patient.oldDose < 64 : this.props.patient.orderQuantity < 64) {
          adminDate.add(7, 'days');
          if (oldAdminDate.add(7, 'days').isBefore(currentShipmentDate, 'd')) {
            adminDate.add(7, 'days');
          }
        } else {
          adminDate.add(4, 'weeks');
        }
      }
    }
    let currentHour = new Date().getHours();
    let businessDays = (this.props.isRural ? deliveryTimes.rural: deliveryTimes.urban) + 1;

    let shipmentDate = adminDate;
    while (businessDays > 0 || shipmentDate.day() >= 5 || shipmentDate.day() === 0) {
      shipmentDate.subtract(1, 'd');
      if (!(shipmentDate.day() === 0 || shipmentDate.day() === 6)) {
        if (businessDays === 2) {
          let deliveryDate = shipmentDate.format('DD/MM/YY');
          this.setState(state=>({...state, deliveryDate}))
        }
        businessDays -= 1;
      }
    }

    this.props.change('user.patient.shipmentDate', shipmentDate.format(dateFormatString));
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      setTimeout(() => {
        if (window.$('.error').length) {
          window.$('html, body').animate({
              scrollTop: window.$('.error').offset().top - 200
          }, 200);
        }
      }, 10);
    }
    return false;
  }

  render() {
    let activePatients = this.props.patients

    const ordersAmount = (this.props.orders.length / 2)
    const maxPatients = this.props.user.buvidal.maxPatients || 10
    let remainingOrders = Math.max(0, Math.min(maxPatients, (activePatients.length - ordersAmount) + 1))
    if(ordersAmount >= this.props.user.buvidal.maxPatients) {
      remainingOrders = 0
    }
    const canOrder = !!(remainingOrders !== 0)

    if(!canOrder) {
      this.props.history.replace({
        pathname: "/buvidal/patients"
      });
    }

    return (
      <div className="content">
        <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
          <h1>Ordering Initiation Packs</h1>
          <Flex>
          <Col>
          <p>A Buvidal<sup>®</sup> Weekly Initiation Pack includes:</p>
          <ul>
            <li>
              <strong>1 x Buvidal<sup>®</sup> Weekly 16 mg</strong>
            </li>
            <li>
              <strong>2 x Buvidal<sup>®</sup> Weekly 8 mg</strong>
            </li>
          </ul>
          <p>
            The pack must be allocated to an <strong>enrolled patient</strong>{' '}
            before a new Initiation Pack can be ordered.
          </p>

          <h2>Ordering times</h2>
          <ul>
            <li>
              Daily order cut-off time is 11.00 am AEDT. Orders confirmed after 11.00 am AEDT will be processed the next business day.
            </li>
            <li>
              Order are dispatched Monday through Thursday. Orders confirmed after 11.00 am AEDT on Thursday will not ship until the following Monday.
            </li>
          </ul>
          </Col>
          <Col>
           <img src={OrderCycleImg} alt='order cycle' />
          </Col>
          </Flex>
          <p>
            If you have nominated a pharmacy to receive orders, they will
            receive copies of order confirmations.
          </p>
          <h2>Confirm order</h2>

          <Checkbox
            styling="float"
            name="orderConfirmationAuth"
            options={[
              {
                value: "true",
                label:
                  <p style={{marginBottom: '0px'}}>I confirm my order of a Buvidal<sup>®</sup> Initiation Pack. Please have the order dispatched to the nominated account holder.</p>,
              },
            ]}
            validate={[required]}
          />
          <Checkbox
            styling="float"
            name="longActingAuth"
            options={[
              {
                value: "true",
                label:
                  "I understand that I am responsible for obtaining authorisation to prescribe long-acting buprenorphine as/where required by my State’s regulations.",
              },
            ]}
            validate={[required]}
          />
          <Checkbox
            styling="float"
            name="cannotOrderAnotherAuth"
            options={[
              {
                value: "true",
                label:
                  "I understand that I cannot order another Initiation Pack until I have allocated this order to an enrolled patient.",
              },
            ]}
            validate={[required]}
          />
          <Checkbox
            styling="float"
            name="initiationOnlyAuth"
            options={[
              {
                value: "true",
                label:
                  "I understand that this order is for treatment initiation only. Ongoing maintenance treatment must be prescribed and dispensed as per standard clinical practice.",
              },
            ]}
            validate={[required]}
          />
          <Box>
            <p><strong>If this is your first order, please note that you will not receive an order confirmation, and your Initiation Pack order will not ship until the account is confirmed, Drug and Therapeutics Committee approval, or equivalent, is confirmed (if relevant), your registration is validated, and patient enrolment opens.</strong></p>
          </Box>
          <Navigation primaryText="Place order" primaryLink="/buvidal/patients" />
          <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting)} />
        </form>
      </div>
    )
  }
}

DrugOrderDetails = reduxForm({
  form: 'DrugOrderDetails' // a unique name for this form
})(DrugOrderDetails);

export default connect(mapStateToProps, mapDispatchToProps)(DrugOrderDetails);

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { closeModal } from 'modules/modals/actions';
import { openModal } from 'modules/modals/actions';

import LeaveSite from "../LeaveSite";
import ProductProfileUpdated from "../ProductProfileUpdated";

function mapStateToProps(state) {
  return {
    currentModal: state.modals.currentModal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
  }, dispatch);
}

class Modal extends Component {
  render() {
    if (this.props.currentModal) {
      return (
        <div className="overlay-modal">
          <div className="modal">
            <img className="logo" src={require('../../../img/medax/medax_logo.png')} alt="medax-logo" />
            {/* <button className="closeButton" onClick={() => this.props.closeModal()}></button> */}
            <LeaveSite />
            <ProductProfileUpdated />
          </div>
        </div>
      )
    }
    else {
      return "";
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

import React from "react";
import styled, {css} from "styled-components"

const StyledBox = styled.div`
  border: 2px solid #7E7E7E;
  padding: 20px;
  margin-bottom: 20px;

  p {
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }

  ${props => props.noBorder && css`
    border: none;
    padding: 0;
  `}
`

const Box = ({children, noBorder}) => {
  return (
    <StyledBox noBorder={noBorder}>
      {children}
    </StyledBox>
  )
}

export default Box
import React, { Component } from "react";
import ExternalLink from "components/ExternalLink"

class PdfDocument extends Component {
  render() {
    return (
      <div className={"pdf-link" + (this.props.last?" last-of-element":"")} onClick={this.props.onClick}>
        {this.props.external ?
          <ExternalLink className="pdf-link-wrapper" rel="noopener noreferrer" href={this.props.link} >
            <div className="left">{this.props.documentName}</div>
            <div className="right"></div>
          </ExternalLink>
          :
          <a className="pdf-link-wrapper" rel="noopener noreferrer" target="_blank" href={this.props.link} >
            <div className="left">{this.props.documentName}</div>
            <div className="right"></div>
          </a>
        }
      </div>
    )
  }
}

export default PdfDocument;
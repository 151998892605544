import React, { Component } from "react";
import { reduxForm, SubmissionError } from 'redux-form';
import { Checkbox } from "components/Forms"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";
import { requestPatchUser } from '../../../modules/user/actions'

import { _required } from "utils/validation"

import QualityUseEducationSummary from '../dashboard/components/QualityUseEducationSummary'

let required = _required();

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class Step4 extends Component {

  submit(_results) {
    return new Promise((resolve, reject) => {
      this.props.requestPatchUser({
        data: {
          buvidal: {
            onboardingStep: 5
          }
        },
        callback: (result) => {
          if (!result.error) {
            this.props.history.push("/buvidal/patients");
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <OnboardingContainer step="4">
        <QualityUseEducationSummary />

        {/* <hr /> */}
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autocomplete="off">
          <Checkbox styling="float" name="authorisation" options={[{ value: 'true', label: <p>I confirm that I am experienced with prescribing and administering long-acting injectable buprenorphine formulations and have familiarised myself with the full Buvidal<sup>®</sup> Weekly Product Information, TGA-approved education booklet for healthcare professionals, and the information summarised above.</p> }]} validate={[required]} />
          <Navigation step={4} secondaryText="Back" secondaryLink="/buvidal/setup/step-3/" primaryOnClick={() => { }} primaryText="Next" primaryLink="/buvidal/patients" />
        </form>

      </OnboardingContainer>
    )
  }
}

Step4 = reduxForm({
  form: 'Step4', // a unique name for this form
})(Step4);

export default connect(null, mapDispatchToProps)(Step4);
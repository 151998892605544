import {
  RECEIVE_PATCH_USER,
  RECEIVE_AUTH,
  RECEIVE_LOGOUT,
} from './actions';

function mergeObjects(o1,o2) {
  if (o1 === undefined) { 
    return o2;
  }
  if (o2 === undefined) { 
    return o1;
  }
  if (o1 === null) { 
    return o2;
  }
  if (typeof o2 !== "object" || o2 === null) {
    return o2;
  }

  if (Array.isArray(o1)) {
    if (!o1.length) { 
      return o2;
    }
    if (!o1[0].id) {
      return o2;
    }
    if (!o2.length) { 
      return o1;
    }
    let ret = [];
    for (let i = 0; i < Math.max(o1.length, o2.length); i++){
      ret[i] = mergeObjects(o1[i], o2[i]);
    }
    return ret;
  }

  if (!Object.keys(o1).length) { 
    return o2;
  }
  if (!Object.keys(o2).length) { 
    return o1;
  }

  const keys = Object.keys(o1).concat(Object.keys(o2).filter(function (item) {
      return Object.keys(o1).indexOf(item) < 0;
  }));

  let ret = {};
  for (let key of keys) {    
    ret[key] = mergeObjects(o1[key], o2[key]);
  }
  return ret;
}

function processUserMeta(_newData, currentData) {
  //console.log('processing user meta...', _newData);
  let newData = _newData;
  return mergeObjects(currentData, newData);
}

export const user = (state = {}, action) => {
  switch(action.type) {
    case RECEIVE_AUTH:
    case RECEIVE_LOGOUT: {
      let user = processUserMeta(action.payload, {});
      return {...user, verifyFinished: state.verifyFinished};
    }
    case RECEIVE_PATCH_USER: {
      let newData = processUserMeta(action.payload, state);
      delete newData.password;
      delete newData.currentPassword;
      return {...newData};
    }
    default : {
      return state;
    }
  }
};

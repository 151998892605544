import React, { Component } from "react";
import Sidebar from "./components/Sidebar"
import PrivacyStatementContent from "./components/PrivacyStatementContent";

class ProductPrivacyStatement extends Component {
    render() {
        return (
            <main className={"dashboard content-page " + this.props.type}>
                <Sidebar />
                <div className="content">
                <PrivacyStatementContent />
                </div>
            </main>
        )
    }
}

export default ProductPrivacyStatement;
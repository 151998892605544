import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, formValueSelector, change } from 'redux-form';
import { requestPatchUser } from '../../../modules/user/actions'

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";
import { Field, Select, Checkbox } from "components/Forms"
import { state } from "utils/selectValues";
import { additonalContactRole } from "utils/selectValues"
import filterInitialValues from 'utils/filterInitialValues'
import HCLOrPharmacyNumSearch from "utils/HCLOrPharmacyNumSearch";
import { _required, _email, _mobile } from "utils/validation"
let required = _required();
let email = _email();
let mobile = _mobile();

let requiredGroup = (value, allValues) => {
  let exists = false;
  if (!allValues.user || !allValues.user.buvidal) return undefined;

  // console.log(JSON.stringify(allValues.user.buvidal));
  for (let val of Object.keys(allValues.user.buvidal).filter(key=>key.indexOf('additionalContact') !== -1)) {
    if (allValues.user.buvidal[val] !== undefined && allValues.user.buvidal[val] !== '') {
      exists = true;
    }
  }

  if (!exists) return undefined;
  return ((value && !(value.length === 0)) || typeof value === 'number' ? undefined : 'This field is required');
}


function mapStateToProps(state) {
  return {
    user: state.user,
    Step5Form: state.form.Step5,
    initialValues: {
      user: state.user
    },
    fields: (state.form.Step5 && state.form.Step5.registeredFields) || {},
    treatmentSites: state.user.treatmentSites ? state.user.treatmentSites : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    change
  }, dispatch);
}

class Step5 extends Component {

  submit(_results) {
    return new Promise((resolve, reject) => {

      let results = filterInitialValues(_results, {...this.props.fields, 'user.buvidal.treatmentSite': null}, this.props.initialValues);
      if (!results.hasChanged) {
        this.props.history.push("/buvidal/setup/step-6");
        return resolve();
      }
      results.user.buvidal.onboardingStep = 6;

      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push("/buvidal/setup/step-6");
            return resolve();
          } else {
            this.props.history.push("/buvidal/setup/step-6");
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      setTimeout(() => {
        if (window.$('.error').length) {
          window.$('html, body').animate({
              scrollTop: window.$('.error').offset().top - 200
          }, 200);
        }
      }, 10);
    }
    return false;
  }

  render() {
    const { user } = this.props;
    const communications = [
      { value: 'email', label: <strong>{user.email}</strong> },
      { value: 'mobile-number', label: <strong>{user.mobileNumber}</strong> },
    ];

    const authorisation = [
      { value: 'true', label: 'I confirm that I am currently authorised to prescribe opioid dependence treatments in my state.' },
    ];

    const order = [
      { value: 'direct', label: 'Ship drug orders directly to my clinic / practice' },
      { value: 'pharmacy', label: 'I have made arrangements with a local pharmacy to receive orders on my behalf' },
    ]

    const dontKnowNumber = [
      {value: 'dont-know', label: 'I do not know the account number'}
    ]

    const {order_shipping, additionalContact_role, hcl_account_number, symbion_account_number, treatmentSite, dontKnowNumber: dontKnowNumber_value} = this.props.Step5Form && this.props.Step5Form.values && this.props.Step5Form.values.user && this.props.Step5Form.values.user.buvidal ?  this.props.Step5Form.values.user.buvidal : {}
    const dontKnowNumberSelected = !!(dontKnowNumber_value || []).length;

    const accountNotFound = !(this.props.Step5Form && this.props.Step5Form.active && this.props.Step5Form.active.includes('_account_number')) 
                              && ((order_shipping === 'pharmacy' && symbion_account_number) || (order_shipping === 'direct' && hcl_account_number)) 
                              && !treatmentSite;

    return (
      <OnboardingContainer step="5">
        <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
          <input hidden autoComplete="off" />
          <h1>Program profile settings</h1>
          
          <h2>Notification preferences</h2>
          <p>Please select your preferred option for notifications. For urgent communications, we will use both email and mobile numbers as required.</p>
          <Checkbox styling="float" name="user.buvidal.communications" options={communications} validate={[required]} />
          <p>Incorrect details? Update your <NavLink to="/my-account/">MedAX account</NavLink>.</p>
          
          <h2>Ordering Initiation Packs</h2>
          <p>Participation in the PFP requires ordering a Buvidal<sup>®</sup> Weekly Initiation Pack. Initiation Packs include:</p>
          <ul>
            <li>1x Buvidal<sup>®</sup> <strong>Weekly 16 mg</strong></li>
            <li>2x Buvidal<sup>®</sup> <strong>Weekly 8 mg</strong></li>
          </ul>
          <p>The pack must be allocated to an <strong>enrolled patient</strong> before a new Initiation Pack can be ordered.</p>
          <p>Please confirm where drug orders should be shipped to. Please note that clinics/practices require a HCL account approved for Buvidal<sup>®</sup> orders; pharmacies require a Symbion account approved for Buvidal<sup>®</sup> orders.</p>
          <Checkbox styling="float" radio name="user.buvidal.order_shipping" options={order} validate={[required]} />

          {order_shipping && 
          <>
            <h2>Drug order account</h2>
            <p>Please confirm the Account Number (HCL for treatment sites; Symbion for pharmacies)</p>
            {order_shipping === 'direct' &&
              <Field styling="float l" name="user.buvidal.hcl_account_number" type="text" title="HCL account number" onChange={()=>HCLOrPharmacyNumSearch(this, 'Step5Form')} validate={dontKnowNumberSelected ? [] : [required]} />          
            }
            {order_shipping === 'pharmacy' &&
              <>
                <Field styling="float l" name="user.buvidal.symbion_account_number" type="text" title="Symbion account number" onChange={()=>HCLOrPharmacyNumSearch(this, 'Step5Form')} validate={dontKnowNumberSelected ? [] : [required]} />
                <Field styling="float r" name="user.buvidal.pharmacy" type="text" title="Pharmacy" disabled/>          
              </>
            }
            {accountNotFound && 
              <p className='warning'>Account number not found. Please complete the Treatment Site Details below and contact the PFP Administrator for assistance on 1300 862 994</p>
            }
            <Checkbox styling="float" name="user.buvidal.dontKnowNumber" options={dontKnowNumber} />
            {dontKnowNumberSelected && 
              <p className='warning'>You can proceed with registration, but it cannot be validated without confirming a valid account. Please contact the PFP Administrator for assistance on 1300 862 994.</p>
            }
          </>
          }
          
          <h2>Treatment site</h2>
          <p>If Initiation Packs are being shipped directly to your practice, please review and confirm the following as your shipping address. Be sure to include any special delivery instructions.</p>
          <p>If Initiation Packs are being shipped to a pharmacy, even one that is co-located with your practice, please confirm where treatment will be administered.</p>
          <Field name="user.buvidal.clinicPracticeName" type="text" title="Clinic/Practice name" validate={[required]}/>
          <Field styling="float l" name="user.buvidal.AddressLine_1" type="text" title="Address Line 1" validate={[required]}/>
          <Field styling="float r" name="user.buvidal.AddressLine_2" type="text" title="Address Line 2" />          
          <Field styling="float l" name="user.buvidal.suburb" type="text" title="Suburb" validate={[required]}/>
          <Field styling="float r" name="user.buvidal.postcode" type="number" title="Postcode" validate={[required]}/>          
          <Select styling="float l" options={state} name="user.buvidal.state" type="text" title="State" validate={[required]}/>          
          <Field styling="float r" name="user.buvidal.phone" type="text" title="Phone" validate={[required]}/>          
          {order_shipping !== 'pharmacy' && <Field name="user.buvidal.specialDeliveryInstructions" type="text" title="Special delivery instructions" />}          

          {/* <Field styling="float l" name="user.buvidal.treatmentSite" type="text" title="Treatment site" style={height:0} /> */}

          {order_shipping === 'direct' &&
          <>
            <h2>Drug shipment contact</h2>
            <p>Please supply the contact details of the person responsible for receiving drug orders.</p>
            <Field styling="float l" name="user.buvidal.additionalContact_firstName" type="text" title="First name" validate={[required]} />
            <Field styling="float r" name="user.buvidal.additionalContact_lastName" type="text" title="Last name" validate={[required]} />
            <Field styling="float l" name="user.buvidal.additionalContact_emailAddress" type="email" title="Email address" autoCapitalize="none" autoCorrect="none" validate={[required, email]} />
            <Field styling="float r" name="user.buvidal.additionalContact_mobileNumber" type="tel" title="Mobile number" validate={[required, mobile]} />
            <Select styling="float l" options={additonalContactRole} name="user.buvidal.additionalContact_role" type="text" title="Role" validate={[required]} />
            {additionalContact_role === 'Other' &&
              <Field styling="float r" name="user.buvidal.additionalContact_specifyRole" type="text" title="Specify role" />            
            }
          </>
          }
          
          <h2>Authorisation</h2>
          <Checkbox styling="float" name="authorisation" options={authorisation} validate={[required]} />

          <Navigation step={5} secondaryText="Back" secondaryLink="/buvidal/setup/step-4/" primaryText="Next"
            primaryOnClick={()=>{}} />
        </form>
      </OnboardingContainer>
    )
  }
}

Step5 = reduxForm({
  form: 'Step5', // a unique name for this form
})(Step5);

export default connect(mapStateToProps, mapDispatchToProps)(Step5);
import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import PinInput from "./components/PinInput";
import { reduxForm, SubmissionError } from 'redux-form';
import { requestForgotPassword, requestPasswordReset } from '../../modules/user/actions'
import getQueryParams from "utils/getQueryParams";

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestForgotPassword,
    requestPasswordReset,
  }, dispatch);
}


class ConfirmPin extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    let values = getQueryParams(this.props.location.search);
    let pinLength = values.email? 6 : 4;
    this.setState(state=>({...state, pinLength, email: values.email, mobile: values.mobile, loading: false}));
  }

  submit(results) {
    return new Promise((resolve, reject) => {
      if (!results.pin || results.pin.length < this.state.pinLength) {
        return reject(new SubmissionError({
          'pin': 'Please fill in all numbers.'
        }));
      }

      this.props.requestPasswordReset({
        data: {
          email: this.state.email,
          mobileNumber: this.state.mobile,
          token: results.pin,
          type: 'check-token',
        },

        callback: (result) => {
          if (!result.err) {
            let queryString = `pin=${results.pin}&firstName=${result.firstName}&lastName=${result.lastName}&`;
            
            if (this.state.email) {
              queryString += `email=${this.state.email}`;
            } else {
              queryString += `email=${this.state.mobileNumber}`;
            }

            this.props.history.push(`/forgot-password-change?` + queryString);
            return resolve();
          } else {
            if (result.err.message === 'PIN Limit Exceeded') {
              this.props.history.push(`/too-many-pin-attempts`);
            }
            return reject(new SubmissionError({
              pin: result.err.message
            }));
          }
        }
      });
    });
  }

  resendCode() {
    this.setState(state=>({...state, loading: true}));
    let isWaiting = true;
    setTimeout(() => {
      if (!isWaiting) {
        this.setState(state=>({...state, loading: false}));
      } else {
        isWaiting = false;
      }
    }, 1000);
    this.props.requestForgotPassword({
      data: {
        email: this.state.email,
        mobileNumber: this.state.mobile
      },
      callback: (result) => {
        if (result.err && result.err.message === 'SMS Limit Exceeded') {
          this.props.history.push(`/too-many-sms-attempts`);
        } else {
          if (!isWaiting) {
            this.setState(state=>({...state, loading: false}));
          } else {
            isWaiting = false;
          }
        }
      }
    });
  }

  render() {
    if (this.state.email) {
      return (
        <ContainerBox type="signup-confirm-email" title="Confirm email" isLoading={this.props.submitting || this.state.loading}>
          <p><strong className="blue">{this.state.email}</strong></p>
          <p>A message with a verification code was sent to your email address. Please enter the code below.</p>
          <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
            <PinInput name="pin" length={6} resendCode={() => this.resendCode()} onSubmit={this.props.handleSubmit(results => this.submit(results))} />
            <Navigation secondaryText="Back" secondaryLink="/signup" primaryText="Next" primaryLink="/signup-enter-mobile" />
          </form>

        </ContainerBox>
      )
    } else {
      return (
        <ContainerBox type="forgot-password-confirm-mobile" title="Enter mobile" isLoading={this.props.submitting || this.state.loading}>
          <p><strong className="blue">{this.state.mobile}</strong></p>
          <p>A message with a verification code was sent to your mobile phone. Please enter the code below.</p>
          <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
            <PinInput name="pin" length={4} resendCode={()=>this.resendCode()} onSubmit={this.props.handleSubmit(results => this.submit(results))}/>
            <Navigation secondaryText="Back" secondaryLink="/forgot-password" primaryText="Next" primaryLink="/forgot-password-change"/>
          </form>
        </ContainerBox>
      )
    }
  }
}

ConfirmPin = reduxForm({
  form: 'ConfirmPin' // a unique name for this form
})(ConfirmPin);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPin);
import React, { Component } from "react";
import { reduxForm, SubmissionError } from "redux-form";
import { Checkbox } from "components/Forms";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ExternalLink from "components/ExternalLink";
import styled from "styled-components";
import Box from "new-components/Education/Box";

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";
import { requestPatchUser } from "../../../modules/user/actions";
import OrderCycleImg from '../../../img/product/order_cycle.png'

import { _required } from "utils/validation";

const Col = styled.div`
  width: calc(50% - 10px);
  display: inline-block;
  flex-direction: column;

  img {
    width: 100%;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 550px) {
    display: block;
  }
`

let required = _required();

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestPatchUser,
    },
    dispatch
  );
}

class Step6 extends Component {
  submit(_results) {
    return new Promise((resolve, reject) => {
      this.props.requestPatchUser({
        data: {
          buvidal: {
            initialOrderSubmitted: true,
            onboardingStep: 7,
          },
        },
        callback: (result) => {
          if (!result.error) {
            // this.props.history.push("/buvidal/patients");
            window.$('html, body').animate({
              scrollTop: 0
            }, 200);
            return resolve();
          } else {
            return reject(
              new SubmissionError({
                _error: result.error.message,
              })
            );
          }
        },
      });
    });
  }

  render() {
    const { user } = this.props;
    const {initialOrderSubmitted} = user.buvidal

    console.log(initialOrderSubmitted)

    return (
      <OnboardingContainer step="6">
        <form
          onSubmit={this.props.handleSubmit((results) => this.submit(results))}
          autocomplete="off"
        >
          <h1>Ordering Initiation Packs</h1>
          {!initialOrderSubmitted && <>
          <Flex>
          <Col>
          <p>A Buvidal<sup>®</sup> Weekly Initiation Pack includes:</p>
          <ul>
            <li>
              <strong>1 x Buvidal<sup>®</sup> Weekly 16 mg</strong>
            </li>
            <li>
              <strong>2 x Buvidal<sup>®</sup> Weekly 8 mg</strong>
            </li>
          </ul>
          <p>
            The pack must be allocated to an <strong>enrolled patient</strong>{' '}
            before a new Initiation Pack can be ordered.
          </p>

          <h2>Ordering times</h2>
          <ul>
            <li>
              Daily order cut-off time is 11.00 am AEDT. Orders confirmed after 11.00 am AEDT will be processed the next business day.
            </li>
            <li>
              Orders are dispatched Monday through Thursday. Orders confirmed after 11.00 am AEDT on Thursday will not ship until the following Monday.
            </li>
          </ul>
          </Col>
          <Col>
           <img src={OrderCycleImg} alt='order cycle' />
          </Col>
          </Flex>
          <p>
            If you have nominated a pharmacy to receive orders, they will
            receive copies of order confirmations.
          </p>
          <h2>Confirm order</h2>

          <Checkbox
            styling="float"
            name="orderConfirmationAuth"
            options={[
              {
                value: "true",
                label:
                  <p style={{marginBottom: '0px'}}>I confirm my order of a Buvidal<sup>®</sup> Initiation Pack. Please have the order dispatched to the nominated account holder.</p>,
              },
            ]}
            validate={[required]}
          />
          <Checkbox
            styling="float"
            name="longActingAuth"
            options={[
              {
                value: "true",
                label:
                  "I understand that I am responsible for obtaining authorisation to prescribe long-acting buprenorphine as/where required by my State’s regulations.",
              },
            ]}
            validate={[required]}
          />
          <Checkbox
            styling="float"
            name="cannotOrderAnotherAuth"
            options={[
              {
                value: "true",
                label:
                  "I understand that I cannot order another Initiation Pack until I have allocated this order to an enrolled patient.",
              },
            ]}
            validate={[required]}
          />
          <Checkbox
            styling="float"
            name="initiationOnlyAuth"
            options={[
              {
                value: "true",
                label:
                  "I understand that this order is for treatment initiation only. Ongoing maintenance treatment must be prescribed and dispensed as per standard clinical practice.",
              },
            ]}
            validate={[required]}
          />
          <TextBox />
          <Navigation
            step={6}
            primaryOnClick={() => {}}
            secondaryText="Back"
            secondaryLink="/buvidal/setup/step-5/"
            primaryText="Place order"
            primaryLink="/buvidal/patients"
          />
          </> }
          {initialOrderSubmitted && <> 
            <p>Your order has been submitted. A confirmation email will be sent once the order is approved for processing.</p>
            <TextBox />
            <Navigation
              step={6}
              primaryText="Continue"
              primaryLink="/buvidal/patients"
            />
          </>}
        </form>
      </OnboardingContainer>
    );
  }
}

const TextBox = () => {
  return (
    <Box>
      <p><strong>If this is your first order, please note that you will not receive an order confirmation, and your Initiation Pack order will not ship until the account is confirmed, Drug and Therapeutics Committee approval, or equivalent, is confirmed (if relevant), your registration is validated.</strong></p>
    </Box>
  )
}

Step6 = reduxForm({
  form: "Step6", // a unique name for this form
})(Step6);

export default connect((state) => {return {user: state.user,}}, mapDispatchToProps)(Step6);

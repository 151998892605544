export const _required = message => (value => ((value && !(value.length === 0)) || typeof value === 'number' ? undefined : (message || 'This field is required')));

export const _email = message => (value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? (message || 'Invalid email') : undefined));

export const _password = () => (value => {
  if (!value) {
    return undefined;
  }
  if (value.length < 8) {
    return 'Password must contain at least 8 characters';
  }
  if (value.length < 8) {
    return 'Password must contain at least 8 characters';
  }
  if (!/(?=.*\d)(?=.*[a-z])/.test(value)) {
    return 'Password must contain at least 1 letter and 1 number';
  }
});

export const _aphra_value = () => (value => {
  if (!value) {
    return undefined;
  }

  if (value.length < 13) {
    return 'APHRA Number must be at least 13 characters';
  }
});

export const _mobile = () => (value => {
  let errorString = 'Invalid mobile number';

  if (!value) {
    return undefined;
  }
  let phoneNumber = value.replace(/\D/g,'');

  if (phoneNumber.substr(0, 2) === '04' && phoneNumber.length === 10) {
    phoneNumber = phoneNumber.replace('04', '+614');
  } else if (phoneNumber.substr(0, 3) === '614' && phoneNumber.length === 11) {
    phoneNumber = phoneNumber.replace('614', '+614');
  } else if (phoneNumber.substr(0, 1) === '4' && phoneNumber.length === 9) {
    phoneNumber = phoneNumber.replace('4', '+614');
  } else {
    return errorString;
  }

  if (phoneNumber.length < 12) {
    return errorString;    
  }

  return undefined;
});

export default function hasBuvidalAccess(user) {

  if (!user.buvidal || !user.buvidal.isValidated) {
    return false;
  }

  return true;

  // if (user.profession === "Pharmacist" && user.meta.specialisation.includes("Managing - NSDAS") && user.state === "WA") {
  //   return true;
  // }

  // if (!(user.meta.specialisation 
  //   && user.meta.specialisation.length 
  //   && user.meta.specialisation.find(a => a.indexOf("Addiction") !== -1))) {
  //   return false;
  // }

  // if (user.profession === "General Practitioner" || user.profession === "Advanced Trainee" || user.profession === "Specialist") {
  //   return true;
  // }

  // return false;
}
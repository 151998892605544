import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import { Field, Select, Checkbox } from "components/Forms"
import { reduxForm, SubmissionError } from 'redux-form';
import { requestPatchUser } from '../../modules/user/actions'
import { _required, _aphra_value } from "utils/validation"
import { title, state, profession, specialisation, specialistType } from "utils/selectValues";
import { formValueSelector } from 'redux-form';

let aphra_value = _aphra_value();
let required = _required();

function mapStateToProps(state) {
  return {
    SignupCompleteAccountForm: state.form.SignupCompleteAccount,
    email: state.user.email,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}


class SignupCompleteAccount extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {
      console.log(JSON.stringify(results, null, 2));
      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push('/signup-thanks');
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {

    const alertPreferences = [
      { value: 'true', label: 'I would like to receive notifications when relevant programs are available' },
    ];

    const {specialisation: specialisation_value} = this.props.SignupCompleteAccountForm && this.props.SignupCompleteAccountForm.values && this.props.SignupCompleteAccountForm.values.user && this.props.SignupCompleteAccountForm.values.user.meta ?  this.props.SignupCompleteAccountForm.values.user.meta : {}

    const isOtherSelected = (specialisation_value || []).find(e => e === 'Other')

    return (
      <ContainerBox type="signup-complete-account" title="Complete your account" isLoading={this.props.submitting}>
        <p>Hello <strong className="blue">{this.props.email}</strong>, please finish signing up below.</p>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <input hidden autoComplete="off" />
          <Field styling="float l" name="user.ahpra" type="text" title="Your AHPRA Number" validate={[aphra_value, required]} />
          <Select styling="float r" name="user.title" type="text" title="Title" options={title} validate={[required]} />
          <Field styling="float l" name="user.firstName" type="text" title="First name" validate={[required]} />
          <Field styling="float r" name="user.lastName" type="text" title="Last name" validate={[required]} />
          <Select styling="float l" name="user.state" type="text" title="State" options={state} validate={[required]} />
          <Select styling="float r" name="user.profession" type="text" title="Profession" options={profession} validate={[required]} />
          <Select styling="float l" name="user.meta.specialisation" type="text" title="Your specialisation (all that apply)" options={specialisation} isMulti validate={[required]} />
          {isOtherSelected && <Field styling="float r" name="user.meta.specialisation_other" type="text" title="Other specialisation" validate={[required]} />}
          <Checkbox styling="float single" name="user.meta.alertPreferences" options={alertPreferences} single/>
          
          <Navigation primaryText="Next" primaryLink="/signup-thanks" />
        </form>

      </ContainerBox>
    )
  }
}


SignupCompleteAccount = reduxForm({
  form: 'SignupCompleteAccount' // a unique name for this form
})(SignupCompleteAccount);

const selector = formValueSelector('SignupCompleteAccount')
SignupCompleteAccount = connect(
  state => {
    // can select values individually
    const profession = selector(state, 'user.profession');

    return {
      profession
    }
  }
)(SignupCompleteAccount);

export default connect(mapStateToProps, mapDispatchToProps)(SignupCompleteAccount);
import React, { Component } from "react";
import Sidebar from "./components/Sidebar"
import QualityUseEducationSummary from "./components/QualityUseEducationSummary";
import Box from "new-components/Education/Box";
import Image from "new-components/Education/Image";
import ShowMoreLess from "new-components/Education/ShowMoreLess";

import BlackBox from '../../../img/medax/blackbox.png'
import Img1 from '../../../img/buvidal_education_new/1_CAM-BUV-050 Induction PFP_Graph.png'
import Img2 from '../../../img/buvidal_education_new/2_CAM-BUV-050 Induction PFP_Graph.png'
import Img3a from '../../../img/buvidal_education_new/3_1_CAM.png'
import Img3b from '../../../img/buvidal_education_new/3_2_CAM.png'
import Img4 from '../../../img/buvidal_education_new/5_CAM.png'
import Img5 from '../../../img/buvidal_education_new/6_CAM.png'
import Img6 from '../../../img/buvidal_education_new/7_cam.png'
import Img7 from '../../../img/buvidal_education_new/8_9_CAM.png'
import Img8 from '../../../img/buvidal_education_new/10_CAM.png'
import Img10 from '../../../img/buvidal_education_new/10_1_CAM.png'
import Img11 from '../../../img/buvidal_education_new/CAM_11.png'
import Img12 from '../../../img/buvidal_education_new/12_CAM.png'
import HCPEducationBooklet from '../../../pdf/AU-BUV-2100040_Buvidal-HCP-educational-material.pdf'
import WeeklyPI from '../../../pdf/Buvidal Weekly Product Information.pdf'

class Summary extends Component {
    render() {
        return (
            <main className={"dashboard content-page " + this.props.type}>
                <Sidebar />
                <div className="content">
                    <QualityUseEducationSummary />
                </div>
            </main>
        )
    }
}

export default Summary;
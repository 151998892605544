import React, { Component } from "react";
import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";

class ForgotPasswordAttempts extends Component {
    render() {
        return (
            <ContainerBox type="forgot-password-attempts" title="Too many attempts">
                <p>You have exceeded the number of reset password attempts. Please try again later or contact us on <a href="tel:1300862994">1300 862 994</a> for assistance.</p>
                <img src={require('../../img/accounts/forgot_password_attempts.png')} alt="forgot_password_attempts"/>
                <Navigation primaryText="Back to Log In" primaryLink="/login"/>
            </ContainerBox>
        )
    }
}


export default ForgotPasswordAttempts;
import React from "react"
import Box from "new-components/Education/Box"
import Image from "new-components/Education/Image"
import ShowMoreLess from "new-components/Education/ShowMoreLess"
import Img1 from 'img/buvidal_education_new/1_CAM-BUV-050 Induction PFP_Graph.png'
import Img2 from 'img/buvidal_education_new/2_CAM-BUV-050 Induction PFP_Graph.png'
import Img3a from 'img/buvidal_education_new/3_1_CAM.png'
import Img3b from 'img/buvidal_education_new/3_2_CAM.png'
import Img4 from 'img/buvidal_education_new/5_CAM.png'
import Img5 from 'img/buvidal_education_new/6_CAM.png'
import Img6 from 'img/buvidal_education_new/7_cam.png'
import Img7 from 'img/buvidal_education_new/8_9_CAM.png'
import Img8 from 'img/buvidal_education_new/10_CAM.png'
import Img10 from 'img/buvidal_education_new/10_1_CAM.png'
import Img11 from 'img/buvidal_education_new/CAM_11.png'
import Img12 from 'img/buvidal_education_new/12_CAM.png'

import HCPEducationBooklet from 'pdf/AU-BUV-2100040_Buvidal-HCP-educational-material.pdf'
import WeeklyPI from 'pdf/Buvidal Weekly Product Information.pdf'

import BlackBox from 'img/medax/blackbox.png'

const QualityUseEducationSummary = () => {
  return (<>
        <h1>Quality use of medicines summary</h1>
        <Box noBorder>
          <p><strong>This section is intended for medical practitioners who have read the <a href={HCPEducationBooklet} target='_blank' rel="noopener noreferrer">TGA-approved education booklet for healthcare professionals</a>, and are already familiar with prescribing, administering, and managing patients treated with long-acting injectable buprenorphine formulations. Its purpose is to summarise key information specific to treatment initiation using Buvidal<sup>®</sup> Weekly.</strong></p>
          <p><strong>PLEASE READ THE FULL BUVIDAL<sup>®</sup> WEEKLY PRODUCT INFORMATION BEFORE PRESCRIBING. <a href={WeeklyPI} target='_blank' rel="noopener noreferrer">CLICK HERE TO VIEW AND/OR DOWNLOAD A COPY</a></strong></p>
        </Box>
        <Box>
          <strong style={{textAlign: 'center', display: 'block'}}>BOXED WARNINGS</strong>
          <p><strong>RISK OF SERIOUS HARM OR DEATH WITH INTRAVENOUS INJECTION:</strong> serious harm or death could result if administered intravenously. Buvidal<sup>®</sup> forms a gel depot upon contact with body fluids and may cause occlusion, local tissue damage and thrombo-embolic events, including life threatening pulmonary emboli, if administered intravenously.{' '}
          Additional warnings are associated with <strong>HAZARDOUS AND HARMFUL USE, LIFE THREATENING RESPIRATORY DEPRESSION, CONCOMITANT USE OF BENZODIAZEPINES AND OTHER CENTRAL NERVOUS SYSTEM (CNS) DEPRESSANTS, INCLUDING ALCOHOL.</strong><br />
          Please refer the Buvidal<sup>®</sup> Weekly and Buvidal<sup>®</sup> Monthly Product Information for the full boxed warnings.
          </p>
        </Box>
        {/* <Image src={BlackBox} alt='Black box'/> */}
        <h2>CLINICAL EVIDENCE</h2>
        <p>The efficacy and safety of direct initiation has been a key component of the research program supporting the development of Buvidal<sup>®</sup>.<sup>1</sup></p>
        
        <h3>Phase 2 Opioid Challenge Study<sup>1,2</sup></h3>
        <p><strong>Primary objective:</strong> assess the ability of Buvidal<sup>®</sup> to block opioid effects of hydromorphone.</p>
        <ShowMoreLess>
          <p><strong>Study design:</strong> multisite, double-blind, randomized within-patient study, 47 adults with moderate-to-severe opioid use disorder, evaluating the response to 0, 6, and 18 mg intramuscular hydromorphone after being randomised to Buvidal<sup>®</sup> Weekly 24 mg or 32 mg.</p>
          <p><strong>Primary endpoint:</strong> drug liking on visual analog scale (VAS).</p>
          <p><strong>Secondary measures:</strong> VAS ratings of high, good effect, any effect, alertness and drowsiness, withdrawal and craving.</p>
          <Image src={Img1} alt='Rapid and sustained blockade of opiod effects from the first Buvidal dose' />
          <Image src={Img2} alt='Effective suppressiion of withdrawal from Day 1' />
          <p><strong>Results:</strong> a total of 46 of 47 randomized participants (mean [SD] age, 35.5 [9] years; 76% male [n = 35]) completed the study. Both Buvidal<sup>®</sup> Weekly doses produced immediate and sustained blockade of hydromorphone effects (liking maximum effect, Buvidal<sup>®</sup> Weekly 24mg: effect size{', 0.813; P < .001,'} and Buvidal<sup>®</sup> Weekly 32 mg: effect size{', 0.753; P < .001)'} and suppression of withdrawal (Clinical Opiate Withdrawal Scale, Buvidal<sup>®</sup> Weekly 24 mg: effect size, 0.617{'; P < .001,'} and Buvidal<sup>®</sup> Weekly 32 mg: effect size{', 0.751; P < .001)'}.</p>
        </ShowMoreLess>

        <h3>Pivotal Phase 3 Efficacy Study<sup>1,3</sup></h3>
        <p><strong>Primary objective:</strong> determine whether treatment involving novel Weekly and Monthly Buvidal<sup>®</sup> formulations are non-inferior to a daily sublingual combination of buprenorphine hydrochloride and naloxone hydrochloride (SL BPN/NX) in the treatment of opioid use disorder. </p>
        <ShowMoreLess>
          <p><strong>Study design:</strong> multisite, double-blind, double-dummy randomised phase 3 clinical trial. Participants were treatment-seeking adults with moderate-to-severe opioid use disorder. Participants were randomised onto daily sublingual placebo and Buvidal<sup>®</sup> Weekly (first 12 weeks) and Buvidal<sup>®</sup> Monthly (last 12 weeks) (n = 213) or, daily SL-BPN/NX (24 weeks) with matched weekly and monthly subcutaneous placebo injections (n= 215).</p>
          <p><strong>Primary endpoint:</strong> response rate and mean proportion of opioid-negative urine samples for weeks 1-24. </p>
          <p><strong>Secondary measures:</strong> mean proportion of samples with no evidence of illicit opioid use (weeks 4-24) evaluated by a cumulative distribution function (CDF) was an a priori secondary outcome with planned superiority testing if the response rate demonstrated noninferiority.</p>
          <Image src={Img3b} alt='Primary endpoint' className='img-50'/>
          <Image src={Img3a} alt='Secondary endpoint' className='img-50'/>
          <p><strong>Results:</strong> a total of 428 participants (263 men [61.4%] and 165 women [38.6%]; mean [SD] age, 38.4 [11.0] years) were randomized to the SL BPN/NX group (n = 215) or the Buvidal<sup>®</sup> group (n = 213). The response rates were 31 of 215 (14.4%) for the SL BPN/NX group and 37 of 213 (17.4%) for the Buvidal<sup>®</sup> group, a 3.0% difference (95% CI, −4.0% to 9.9%{'; P < .001).'} The proportion of opioid-negative urine samples was 1099 of 3870 (28.4%) for the SL BPN/NX group and 1347 of 3834 (35.1%) for the Buvidal<sup>®</sup> group, a 6.7% difference (95% CI, −0.1% to 13.6%{'; P < .001).'} Injection site adverse events (none severe) occurred in 48 participants (22.3%) in the SL BPN/NX group and 40 (18.8%) in the Buvidal<sup>®</sup> group.</p>
        </ShowMoreLess>

        <h3>Phase 3 Long-term Safety Study<sup>4</sup></h3>
        <p><strong>Primary objective:</strong> assess long-term safety of Buvidal<sup>®</sup> Weekly and Monthly regimens in adult outpatients with opioid use disorder.</p>
        <ShowMoreLess>
          <p><strong>Study design:</strong> phase 3, open-label, multicentre, 48-week study. Participants were administered Buvidal<sup>®</sup> Weekly (8, 16, 24, or 32 mg) or Buvidal<sup>®</sup> Monthly (64, 96, 128, or 160 mg) with flexible dosing and individualised titration up or down utilising the multiple dosing options. Safety variables, urine toxicology samples, and self-reported illicit opioid use were collected at each visit. 162/227 (71.4%) participants were administered a patient satisfaction survey.</p>
          <p><strong>Results:</strong> 228 opioid-dependent participants enrolled, and 227 participants received Buvidal<sup>®</sup> (37 initiated directly onto Buvidal<sup>®</sup> and 190 converted from sublingual buprenorphine).</p>
          <p>167/227 (73.6%) participants completed the treatment period. 143/227 (63.0%) participants reported at least 1 treatment emergent adverse event (TEAE), and 60/227 (26.4%) reported a drug-related TEAE. 46/227 (20.3%) participants reported injection site reactions, with most (45/46 [97.8%]) reported as mild to moderate. 128/227 (56.4%) of the TEAEs were mild or moderate in severity. Five participants (2.2%) discontinued study drug due to a TEAE, of which 2 cases (0.9%) were injection site related. No serious AEs were attributed to study drug.</p>
          <Image src={Img4} alt='Buvidal safety profile is similar to SL-BPN, with the exception of inhection site reactions' />
          <Image src={Img5} alt='Buvidal maintains suppression of withdrawal symptoms' style={{marginBottom: '25px'}}/>
          <p>SL-BPN/NX: Sublingual buprenorphine/naloxone</p>
          <p>Data presented as mean with standard deviation. COWS: clinical opiate withdrawal scale; COWS scale: 5–12 = mild; 13–24 = moderate; 25–36 = moderately severe; {'>36'} = severe withdrawal.</p>
          <p><strong><sup>*</sup>Current PBS Criteria requires that patients must be stabilised on sublingual buprenorphine +/- naloxone prior to initiation of Buvidal<sup>®</sup></strong></p>
          <Image src={Img6} alt='Buvidal maintains suppression of cravings'/>
          <p>Participants asked: ‘Since your last scheduled assessment visit, indicate your worst or strongest need to use opioids.’</p>
          <p>SL BPN/NX: sublingual buprenorphine/naloxone</p>
          <p>Data presented as mean with standard deviation. VAS: visual analogue scale. VAS scale: 0-100 mm, 0 (no desire/need to use), 100 mm (strongest possible desire/need to use)</p>
          <p style={{marginBottom: '40px'}}><strong><sup>*</sup>Current PBS Criteria requires that patients must be stabilised on sublingual buprenorphine +/- naloxone prior to initiation of Buvidal<sup>®</sup></strong></p>
          <p>The secondary endpoints were effectiveness (unsanctioned substance abuse, withdrawal and cravings measures) and treatment retention.</p>
          <Image src={Img7} alt='Efficacy'/>
          <p>The graph above shows the percentage of participants with opioid-negative urine samples combined with self-reports.</p>
          <p>At end of study, the percentage of the composite outcome comprising illicit opioid-negative urine samples and self-reports was 63.0% (17/37) in new-to-treatment participants and 82.8% (111/190) for participants converted from sublingual buprenorphine.</p>
          <Image src={Img8} alt='Retention'/>
        </ShowMoreLess>

        <h2>BEFORE STARTING TREATMENT<sup>1</sup></h2>
        <p>Patients should be reviewed to ensure there are no contraindications to Buvidal<sup>®</sup> treatment, as follows:</p>
        <ul>
          <li>Hypersensitivity to buprenorphine or to any of the excipients;</li>
          <li>Children less than 16 years of age;</li>
          <li>Severe respiratory insufficiency;</li>
          <li>Severe hepatic insufficiency (Child-Pugh C); or</li>
          <li>Acute alcoholism or delirium tremens.</li>
        </ul>
        <p>Before starting treatment, baseline liver function tests and documentation of viral hepatitis status are recommended. Patients who are positive for viral hepatitis, on concomitant medicinal products and/or who have existing liver dysfunction are at greater risk of liver injury. Regular monitoring of the liver function is recommended.<sup>1</sup></p>
        <p>It is essential that patients are provided accurate information and options regarding their treatment as part of informed decision making and consent. Treatment with long-acting buprenorphine may not suit all patients seeking treatment, some patients will prefer sublingual buprenorphine or methadone treatment, and patient preference should guide treatment.<sup>5</sup></p>

        <h2>DIRECT INITIATION USING BUVIDAL<sup>®</sup> WEEKLY FROM SHORT-ACTING OPIOIDS</h2>
        <Box noBorder>
          <p><strong>To avoid precipitating symptoms of withdrawal, treatment with Buvidal<sup>®</sup> should be started when objective and clear signs of mild to moderate withdrawal are evident. For patients using heroin or short-acting opioids, the initial dose of Buvidal<sup>®</sup> must not be administered until at least 6 hours after the patient last used opioids.</strong></p>
        </Box>
        <p>
        When initiating treatment with Buvidal<sup>®</sup>, it is important to be aware of the partial agonist profile of buprenorphine. The slow onset of the effects of Buvidal<sup>®</sup> Weekly (T<sub>max</sub> 24 hrs) may suggest that precipitated withdrawal is unlikely to occur when initiating depot treatment, however there is a risk if the patient is not in opioid withdrawal at time of first dose, and/or the patient has not disclosed recent use of long-acting opioids such as methadone. To avoid precipitating symptoms of withdrawal, treatment with Buvidal<sup>®</sup> should be started when objective and clear signs of mild to moderate withdrawal are evident<sup>1,5</sup>
        </p>
        <p>
        If you have questions about initiation, contact Camurus Medical via email: <a href='mailto:ausmedinfo@camurus.com'>ausmedinfo@camurus.com</a> or phone: 1800 142 038.
        </p>

        <h2>DOSING<sup>1</sup></h2>
        <p><strong>Recommended starting dose:</strong> Buvidal<sup>®</sup> Weekly 16mg.</p>
        <p><strong>Dose adjustments:</strong> a maximum of two additional 8 mg doses may be administered - at least 1 day apart - to a target dose of 24 mg or 32 mg during the first treatment week.</p>

        <h2>ONGOING TREATMENT<sup>1</sup></h2>
        <p>The recommended dose for the second treatment week is the total dose administered during the week of initiation.</p>
        <Image src={Img10} />

        <h2>SWITCHING TO BUVIDAL<sup>®</sup> MONTHLY<sup>1</sup></h2>
        <p>Patients stabilised on Buvidal<sup>®</sup> Weekly can be transitioned to Buvidal<sup>®</sup> Monthly at the time of their next scheduled Weekly dose, according to the recommended dose conversion table (Table 1).</p>
        <Image src={Img11} alt='Buvidal Weekly dose and recommended corresponging doses of Buvidal Monthly'/>

        <h2>SWITCHING TO SUBLINGUAL BUPRENORPHINE<sup>1</sup></h2>
        <p>Should a patient wish to discontinue long-acting treatment with Buvidal<sup>®</sup>, and instead transfer to daily sublingual buprenorphine treatment, it is recommended that sublingual treatment commence one week after the last dose of Buvidal<sup>®</sup> Weekly, at a dose consistent with the recommended dose conversion (Table 2).</p>
        <Image src={Img12} alt='Buvidal Weekly dose and recommended corresponding doses of sublingual buprenorphine'/>

        <p className='references'>
          <strong>References:</strong> 1. Buvidal<sup>®</sup> Weekly Australian Approved Product Information April 2021. 2. Walsh SL, Comer SD, Lofwall M, et al. JAMA Psychiatry 2017; 74(9): 894-902 3. Lofwall MR, Walsh SL, Nunes EV. JAMA Internal Medicine 2018;178(6): 764–773 4. Frost M et al. Addiction. 2019;114:1416-1426. 5. Lintzeris N, Dunlop A, Masters D (2019) Clinical guidelines for use of depot buprenorphine (Buvidal<sup>®</sup> and Sublocade<sup>®</sup>) in the treatment of opioid dependence. NSW Ministry of Health, Sydney Australia. 
        </p>
  </>)
}

export default QualityUseEducationSummary
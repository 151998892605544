import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import Sidebar from "../components/Sidebar"
import { boolean } from "utils/selectValues";
import { Checkbox } from "components/Forms"
import Warning from "../components/Warning";

import { PatientDetailBox } from "../components/DetailBox"
import getQueryParams from "utils/getQueryParams";
import { requestPatchUser } from 'modules/user/actions';
import Navigation from "../components/Navigation";

import {_required} from "utils/validation"
let required = _required();
const requireYes = value => value !== 'false' ? undefined : 'You must select "Yes" to discontinue patient.';

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient=>patient.id == id)[0];
  } else {
    patient = null;
  }

  return {
    patient,
    confirmDiscontinue: state.form.DidNotProceedPatient && state.form.DidNotProceedPatient.values && state.form.DidNotProceedPatient.values.confirmDidNotProceed
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class DidNotProceedPatient extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      this.props.requestPatchUser({
        data: {
          patient: {
            id: this.props.patient.id,
            state: 'did-not-proceed'
          }
        },
        callback: (result) => {
          if (!result.error) {
            this.props.history.push('/buvidal/patients/');
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {

    if (!this.props.patient) {
      return "No patient selected";
    }

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
            <h1>Patient did not proceed</h1>
            <h2>For patient</h2>
            <PatientDetailBox patient={this.props.patient} />

            <p>If this patient has changed their mind about starting treatment prior to treatment administration, you have the option to change this patient’s status to indicate they did not receive treatment.</p>
            <p>Once you change this patient's status, you will be able to enrol and allocate your available Initiation Pack to a new patient.</p>
            <p>If the Initiation Pack was already dispensed for this patient, it will need to be destroyed. Please contact the PFP Administrator for assistance.</p>

            <h2>Confirmation</h2>
            <Checkbox validate={[required, requireYes]} radio title="Please confirm that you wish to change this patient's status, and that this patient was not treated with the Initiation Pack." styling="float r" name="confirmDiscontinue" options={boolean} />

            <Navigation secondaryText="Back" secondaryLink={"/buvidal/patients"} primaryText="Confirm" primaryLink="" />
          </form>
        </div>
      </main>
    )
  }
}

DidNotProceedPatient = reduxForm({
  form: 'DidNotProceedPatient' // a unique name for this form
})(DidNotProceedPatient);

export default connect(mapStateToProps, mapDispatchToProps)(DidNotProceedPatient);
import {
  OPEN_MODAL, CLOSE_MODAL
} from './actions';

export const modalsReducer = (state = {}, action) => {
  switch(action.type) {
    case CLOSE_MODAL: {
      state = {...state, currentModal: ""}
      break;
    }
    case OPEN_MODAL: {
      if (!(typeof action.payload === 'object')) {
        state = {...state, currentModal: action.payload, data: null}
      } else {
        state = {...state, currentModal: action.payload.modal, data: action.payload.data}
      }
      break;
    }
    default : {
      break;
    }

  }
  return state;
};

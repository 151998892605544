import React, { Component } from "react";

class PatientProgressBar extends Component {
    render() {
        return (
            <div className="progress">
                <ul>
                    <li className={currentProgress("1", this.props.step)}><span className="position">1</span><span className="name">Enrol patient</span></li>
                    <li className={currentProgress("2", this.props.step)}><span className="position">2</span><span className="name">Drug order</span></li>
                    <li className={currentProgress("3", this.props.step)}><span className="position">3</span><span className="name">Confirm drug order</span></li>
                </ul>
            </div>
        )
    }
}

function currentProgress(currentStep, step){
    if (currentStep === step) {
        return "active";
    }
    if (currentStep < step) {
        return "done";
    }
}

export default PatientProgressBar;
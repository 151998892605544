import React, { Component } from "react";

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";
import MiniPI from '../../../img/medax/MiniPI.png'
import Image from "new-components/Education/Image";

class Step1 extends Component {
  render() {
    return (
      <OnboardingContainer step="1">
        <h1>
          Welcome to the Start and Stabilise with Buvidal<sup>®</sup> Weekly
          Product Familiarisation Program (PFP)
        </h1>
        <p>
          Buvidal<sup>®</sup> Weekly is the first long-acting injectable buprenorphine
          (LAIB) formulation in Australia indicated for both initiation and
          maintenance treatment of opioid dependence, within a framework of
          medical, social, and psychological support.<sup>1</sup> It is also the only LAIB
          available in Australia that is indicated for direct initiation without
          prior stabilisation on sublingual buprenorphine or
          buprenorphine/naloxone.<sup>1</sup>
        </p>
        <p>
          A submission has been made to the Pharmaceutical Benefits Advisory
          Committee for listing of Buvidal<sup>®</sup> Weekly for direct
          initiation.<sup>2</sup> The Pharmaceutical Benefits Scheme
          (PBS) listing clinical criteria for all Buvidal<sup>®</sup> products currently
          states: <strong>Patients must be stabilised on sublingual buprenorphine or
          buprenorphine/naloxone prior to commencing treatment with this drug
          for opioid dependence.</strong><sup>3</sup>
        </p>
        <p>
          The purpose of this program is to provide an opportunity for medical
          practitioners, already experienced in the use of LAIB formulations, to
          gain clinical experience in treatment initiation using Buvidal<sup>®</sup> Weekly
          prior to a potential PBS listing through:
        </p>
        <ul>
          <li>
            Information and resources to guide and facilitate treatment
            initiation
          </li>
          <li>
            Access to an initial supply of Buvidal<sup>®</sup> Weekly <strong>(one x 16 mg; up to
            two x 8 mg)</strong> for treatment initiation
          </li>
        </ul>
        <p>
          This program is being administered by N of One Solutions Pty Ltd (PFP
          administrator) on behalf of Camurus Pty Ltd
        </p>
        <p className='references'>
          References: <strong>1.</strong> Buvidal<sup>®</sup> Weekly Australian Approved Product Information
          April 2021. <strong>2.</strong> Pharmaceutical Benefits Advisory Committee (PBAC)
          Meeting Agenda November 2021. Accessed online 21 September 2021 <a href='https://www.pbs.gov.au/industry/listing/elements/pbac-meetings/agenda/pdf/2021/PBAC-meeting-agenda-November-2021.pdf' target='_blank' rel="noopener noreferrer">https://www.pbs.gov.au/industry/listing/elements/pbac-meetings/agenda/pdf/2021/PBAC-meeting-agenda-November-2021.pdf</a>
          {' '}<strong>3.</strong> Pharmaceutical Benefits Scheme Schedule. Accessed online 21
          September 2021 <a href='https://www.pbs.gov.au/pbs/search?term=buvidal%20weekly&analyse=false&search-type=medicines' target='_blank' rel="noopener noreferrer">https://www.pbs.gov.au/pbs/search?term=buvidal%20weekly&analyse=false&search-type=medicines</a>
        </p>
        <Image src={MiniPI} alt='Minimum PI'/>
        <Navigation
          step={1}
          primaryText="Next"
          primaryLink="/buvidal/setup/step-2"
        />
      </OnboardingContainer>
    );
  }
}

export default Step1;

import React, { Component } from 'react';
import moment from 'moment';
import chrono from 'chrono-node';

import Select from 'react-select';
import { components as SelectComponents } from 'react-select';

const createOptionForDate = d => {
  const date = moment.isMoment(d) ? d : moment(d);
  return {
    date,
    value: date.toDate(),
    label: date.calendar(null, {
      sameDay: 'Do MMMM YYYY',
      nextDay: 'Do MMMM YYYY',
      nextWeek: 'Do MMMM YYYY',
      lastDay: 'Do MMMM YYYY',
      lastWeek: 'Do MMMM YYYY',
      sameElse: 'Do MMMM YYYY',
    }),
  };
};

const defaultOptions = [];
// ['today', 'tomorrow', 'yesterday'].map(i =>
//   createOptionForDate(chrono.en_GB.parseDate(i))
// );

const createCalendarOptions = (date = new Date()) => {
  // $FlowFixMe
  const daysInMonth = Array.apply(null, {
    length: moment(date).daysInMonth(),
  }).map((x, i) => {
    const d = moment(date).date(i + 1);
    return { ...createOptionForDate(d), display: 'calendar' };
  });
  return {
    label: moment(date).format('MMMM YYYY'),
    options: daysInMonth,
  };
};

// defaultOptions.push(createCalendarOptions());

const suggestions = [
  'sunday',
  'saturday',
  'friday',
  'thursday',
  'wednesday',
  'tuesday',
  'monday',
  'december',
  'november',
  'october',
  'september',
  'august',
  'july',
  'june',
  'may',
  'april',
  'march',
  'february',
  'january',
  'yesterday',
  'tomorrow',
  'today',
].reduce((acc, str) => {
  for (let i = 1; i < str.length; i++) {
    acc[str.substr(0, i)] = str;
  }
  return acc;
}, {});

const suggest = str =>
  str
    .split(/\b/)
    .map(i => suggestions[i] || i)
    .join('');

const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const Group = props => {
  const { Heading, getStyles, children, label, innerProps, headingProps, cx, theme } = props;
  return (
    <div className="date-month" aria-label={label} css={getStyles('group', props)} {...innerProps}> 
      <Heading
        theme={theme}
        getStyles={getStyles}
        cx={cx}
        {...headingProps}
      >
        {label}
      </Heading>
      <div className="date-weeks">
        {days.map((day, i) => (
          <span key={`${i}-${day}`} className="date-pick-weeks">
            {day}
          </span>
        ))}
      </div>
      <div className="date-pick-container">{children}</div>
    </div>
  );
};

const Option = props => {
  const { data, innerRef, innerProps } = props;
  var currentDate = moment(new Date());

  if (data.display === 'calendar') {
    const styles = {};
    if (data.date.date() === 1) {
      const indentBy = data.date.day();
      if (indentBy) {
        styles.marginLeft = `${indentBy * 14 + 1}%`;
      }
    }
    return (
      <span className={"date-pick" + ((data.date.format('D') == currentDate.format('D'))?" current-date":"")  + ((data.date < currentDate)?" old-date":"")} {...innerProps} style={styles} ref={innerRef}>
          {data.date.format('D')}
      </span>
    );
  } else return <SelectComponents.Option {...props} />;
};

class DatePicker extends Component {

  constructor(props) {
    super(props);
    if (!props.disableCalendar) {
      this.state = {
        options: defaultOptions,
      };
    } else {
      this.state = {
        options: [],
      };
    }
  }

  handleInputChange = value => {
    if (!value) {
      this.setState({ options: defaultOptions });
      return;
    }
    const date = chrono.en_GB.parseDate(suggest(value.toLowerCase()));
    if (date) {
      if (this.props.disableCalendar) {
        this.setState({
          options: [createOptionForDate(date)],
        });
      } else {
        this.setState({
          options: [createOptionForDate(date), createCalendarOptions(date)],
        });
      }
    } else {
      this.setState({
        options: [],
      });
    }
  };
  render() {
    const { value } = this.props;
    const { options } = this.state;
    return (
      <Select
        {...this.props}
        components={{ Group, Option }}
        filterOption={null}
        isMulti={false}
        // isOptionSelected={(o, v) => v.some(i => i.date.isSame(o.date, 'day'))}
        maxMenuHeight={380}
        onChange={this.props.onChange}
        onInputChange={this.handleInputChange}
        options={options}
        value={value}
      />
    );
  }
}

export default DatePicker;
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openModal } from "modules/modals/actions";
import ExternalLink from "components/ExternalLink";


import TermsAndConditionsContent from "../dashboard/components/TermsAndConditionsContent";

function mapStateToProps(state) {}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
    },
    dispatch
  );
}

class Step3 extends Component {
  render() {
    return (
      <OnboardingContainer step="3">
        <TermsAndConditionsContent />
        <Navigation
          step={3}
          secondaryText="Back"
          secondaryLink="/buvidal/setup/step-2/"
          primaryText="I agree"
          primaryLink="/buvidal/setup/step-4"
        />
      </OnboardingContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3);

import React, { Component } from "react";
import ContainerBox from "./components/ContainerBox";

class TooManyPinAttempts extends Component {
  render() {
    return (
      <ContainerBox type="forgot-password-attempts" title="Too many attempts">
        <p>You have exceeded the number of pin attempts. Please try again later or contact us on <a href="tel:1800995888">1800 995 888</a> for assistance.</p>
        <img src={require('../../img/accounts/forgot_password_attempts.png')} alt="too many attempts" />
        <div className="navigation">
          <div className="secondary">
          </div>
          <div className="primary">
            <button onClick={this.props.history.goBack} className="button-type-1">Go Back</button>
          </div>
        </div>
      </ContainerBox>
    )
  }
}

export default TooManyPinAttempts;
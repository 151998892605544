import React, { Component } from "react";
import Sidebar from "./components/Sidebar"

class PatientsPendingValidation extends Component {
  render() {

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <h1>Patient Dashboard</h1>
          <h2>Access pending validation of your registration.</h2>
          <p>Your registration has been submitted for validation. You will receive a notification from the PFP administrator by next business day to confirm approval of your registration and that access to the Patient Dashboard has been activated.</p>
        </div>
      </main>
    )
  }
}


export default PatientsPendingValidation;
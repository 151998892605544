import React, { Component } from "react";

class ProgressBar extends Component {
    render() {
        return (
            <div className="progress">
                <div className="wrapper-w731">
                    <div className="progress-bar">
                        <ul>
                            {progressState(this.props.step, "1")}
                            {progressState(this.props.step, "2")}
                            {progressState(this.props.step, "3")}
                            {progressState(this.props.step, "4")}
                            {progressState(this.props.step, "5")}
                            {progressState(this.props.step, "6")}
                        </ul>
                        <div className="bar">
                            <div className="bar-complete" 
                                style={{
                                    width:(positionBarComplete(this.props.step)),
                                }}>
                            </div>
                            <div className="bar-progress"
                                style={{
                                    width:(positionBarProgress(this.props.step)),
                                }}
                            ></div>
                            <div className="bar-background"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function positionBarProgress(step){
    switch (step) {
        case "1": return "0%";
        case "2": return "20%";
        case "3": return "40%";
        case "4": return "60%";
        case "5": return "80%";
        case "6": return "100%";
    }
}

function positionBarComplete(step){
    switch (step) {
        case "1": return "0%";
        case "2": return "0%";
        case "3": return "20%";
        case "4": return "40%";
        case "5": return "60%";
        case "6": return "100%";
    }
}

function progressState(step, currentStep){
    if (step === currentStep) {
        return <li className="current"><span>{currentStep}</span></li>;
    } 
    else if (step > currentStep) {
        return <li className="done"><span>{currentStep}</span></li>;
    }
    else {
        return <li><span>{currentStep}</span></li>;
    }
}


export default ProgressBar;
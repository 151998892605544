import React, { Component } from "react";
import NavButton from "components/NavButton";

class pageNotFound extends Component {
    render() {
        return (
            <main className={"company " + this.props.type}>
                <div className="content">
                    <div className="wrapper-w880">
                        <h1>404</h1>
                        <h2>We can't seem to find the page your are looking for.</h2>
                        <p><strong>Error code:</strong> 404</p>
                        <br />
                        <NavButton to="/" class="button-type-1">Back Dashboard</NavButton>
                    </div>
                </div>
            </main>
        )
    }
}

export default pageNotFound;
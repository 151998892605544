import React, { Component } from "react";

import Sidebar from "../components/Sidebar";
import ConfirmOrder from "./components/ConfirmOrder";

class PatientConfirmOrder extends Component {
  render() {
    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <ConfirmOrder {...this.props} />
      </main>
    )
  }
}

export default PatientConfirmOrder;
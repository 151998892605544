import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import moment from 'moment'
import capitalise from 'utils/capitalise';

let formatDate = date => moment.utc(date).format('DD/MM/YYYY');

class DetailBox extends Component {
  render() {
    return (
      <div className="detail-box">
        {this.props.location ?
          <div className="detail-box-link"><NavLink to={this.props.location}>Modify Details</NavLink></div>
          : ""}
        <div className="detail-box-content">
          <ul>
            {this.props.children}
          </ul>
        </div>
      </div>
    );
  }
}

export class PatientDetailBox extends Component {
  render() {
    return (
      <DetailBox location={this.props.location} >
        <li className="thirds">
          <span>Initials</span>{this.props.patient.initials}
        </li>
        <li className="thirds">
          <span>Date of Birth</span>{formatDate(this.props.patient.dob)}
        </li>
        <li className="thirds">
          <span>Sex</span>{capitalise(this.props.patient.sex)}
        </li>
      </DetailBox>
    )
  }
}

export class ActivePatientDetailBox extends Component {
  render() {
    return (
      <DetailBox location={this.props.location} >
        <li className="thirds">
          <span>Initials</span>{this.props.patient.initials}
        </li>
        <li className="thirds">
          <span>Date of Birth</span>{formatDate(this.props.patient.dob)}
        </li>
        <li className="thirds">
          <span>Sex</span>{capitalise(this.props.patient.sex)}
        </li>
        <li className="thirds">
          <span>Regimen</span>{(this.props.patient.orderQuantity < 64)?'Weekly':'Monthly'}
        </li>
        <li className="thirds">
          <span>Dosage</span>{"" + this.props.patient.orderQuantity + " mg"}
        </li>
      </DetailBox>
    )
  }
}

export class ExtendedPatientDetailBox extends Component {
  render() {
    return (
      <DetailBox location={this.props.location} >
        <li className="one">
          <span>Treatment site</span>{this.props.patient.treatmentSite.name}
        </li>
        <li className="thirds">
          <span>Initials</span>{this.props.patient.initials}
        </li>
        <li className="thirds">
          <span>Date of Birth</span>{formatDate(this.props.patient.dob)}
        </li>
        <li className="thirds">
          <span>Sex</span>{capitalise(this.props.patient.sex)}
        </li>
        <li className="thirds">
          <span>Treatment stablised on</span>{this.props.patient.stabilisedOn}
        </li>
        <li className="thirds">
          <span>Duration of initial treatment</span>{this.props.patient.durationOfInitialTreatment}
        </li>
        <li className="thirds">
          <span>Primary drug of addiction</span>{capitalise(this.props.patient.drugAddiction.reduce((prev, curr)=>prev + ', ' + curr))}
        </li>
      </DetailBox>
    )
  }
}

export class ExtendedTreatmentDetailBox extends Component {
  render() {
    return (
      <DetailBox location={this.props.location} >
        <li className="thirds">
          <span>Administration date</span>{formatDate(this.props.patient.administrationDate)}
        </li>
        <li className="seconds">
          <span>Date of Shipment</span>{formatDate(this.props.patient.shipmentDate)}
        </li>
        <li className="thirds">
          <span>Regimen</span>{(this.props.patient.orderQuantity < 64)?'Weekly':'Monthly'}
        </li>
        <li className="thirds">
          <span>Dosage</span>{"" + this.props.patient.orderQuantity + " mg"}
        </li>
        <li className="thirds">
          <span>Doses</span>{(this.props.patient.orderQuantity < 64)?'x2':'x1'}
        </li>
        {this.props.show8mg &&
          <li className="thirds">
            <span>Additional Buvidal Weekly 8 mg</span>{(this.props.patient.additional8mgUnitsOrdered > 0)?'x1':'0'}
          </li>
        }
      </DetailBox>
    )
  }
}

export class OrderExtraDetailBox extends Component {
  render() {
    return (
      <DetailBox location={this.props.location} >
        <li className="thirds">
          <span>Date of shipment</span>{formatDate(this.props.patient.additional8mgShipmentDate)}
        </li>
        <li className="thirds">
          <span>Dosage</span>Buvidal Weekly - 8 mg
        </li>
        <li className="thirds">
          <span>Quantity</span>{this.props.patient.additional8mgUnitsOrdered}
        </li>
      </DetailBox>
    )
  }
}
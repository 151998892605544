import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { reduxForm, SubmissionError } from 'redux-form';

import {requestLogin} from '../../modules/user/actions'
import getQueryParams from "utils/getQueryParams";

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import { Field } from "components/Forms"
import formatMobileNumber from "utils/formatMobileNumber"
import {_required, _email} from "utils/validation"

let required = _required();
let email = _email();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestLogin,
  }, dispatch);
}

class Signin extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      let data = {
        password: results.password
      }

      if (results.username.indexOf('@') !== -1) {
        data.email = results.username;
      } else {
        data.mobileNumber = formatMobileNumber(results.username);
      }

      this.props.requestLogin({
        data,
        callback: (result)=>{
          if (result.success) {
            let link = getQueryParams().next;
            if (!link) {
              this.props.history.push('/');
            } else {
              this.props.history.push(`/${link}`);
            }
            return resolve();
          } else {
            return reject(new SubmissionError({
                password: 'Invalid username or password.'
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="signin" title="Log In" isLoading={this.props.submitting} isLoading={this.props.submitting}>
        <p>Access to this site is restricted to AHPRA‐registered medical professionals currently practicing in Australia.</p>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">

          <Field name="username" type="text" title="Enter your email or phone number" validate={[required]} />
          <Field name="password" type="password" title="Enter your password" validate={[required]} />
          <div className="forgot-password"><NavLink to="/forgot-password/">Forgot password?</NavLink></div>
          <Navigation secondaryText="Create an account" secondaryLink="/signup" primaryText="Log in" primaryLink="/" />

        </form>

      </ContainerBox>
    )
  }
}


Signin = reduxForm({
  form: 'Signin' // a unique name for this form
})(Signin);


export default connect(mapStateToProps, mapDispatchToProps)(Signin);
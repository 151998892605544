import React, { Component } from "react";
import Sidebar from "./components/Sidebar"
import { reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Select, Checkbox } from "components/Forms"

import { NavLink } from "react-router-dom";
import NavButton from "components/NavButton";
import { openModal } from 'modules/modals/actions';

import { requestPatchUser } from '../../../modules/user/actions'
import { additonalContactRole } from "utils/selectValues"
import filterInitialValues from 'utils/filterInitialValues'

import { state } from "utils/selectValues";

import { _required, _email, _mobile } from "utils/validation"
import HCLOrPharmacyNumSearch from 'utils/HCLOrPharmacyNumSearch'
let required = _required();
let email = _email();
let mobile = _mobile();

let requiredGroup = (value, allValues) => {
  let exists = false;
  if (!allValues.user || !allValues.user.buvidal) return undefined;

  for (let val of Object.keys(allValues.user.buvidal).filter(key=>key.indexOf('additionalContact') !== -1)) {
    if (allValues.user.buvidal[val] !== undefined && allValues.user.buvidal[val] !== '') {
      exists = true;
    }
  }

  if (!exists) return undefined;
  return ((value && !(value.length === 0)) || typeof value === 'number' ? undefined : 'This field is required');
}

function mapStateToProps(state) {
  return {
    user: state.user,
    SettingsForm: state.form.Settings,
    initialValues: state.user.treatmentSites ? {
      user: state.user
    }:null,
    fields: (state.form.Settings && state.form.Settings.registeredFields) || {},
    treatmentSite: state.form.Settings && state.form.Settings.values ? state.form.Settings.values.user.buvidal.treatmentSite : null,
    treatmentSites: state.user.treatmentSites ? state.user.treatmentSites : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    openModal
  }, dispatch);
}

class Settings extends Component {

  submit(_results) {
    return new Promise((resolve, reject) => {
      let results = filterInitialValues(_results, {...this.props.fields, 'user.buvidal.treatmentSite': null}, this.props.initialValues);
      if (!results.hasChanged) {
        this.props.openModal("ProductProfileUpdated");
        return resolve();
      }

      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            this.props.openModal("ProductProfileUpdated");
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      setTimeout(() => {
        if (window.$('.error').length) {
          window.$('html, body').animate({
              scrollTop: window.$('.error').offset().top - 200
          }, 200);
        }
      }, 10);
    }
    return false;
  }

  render() {
    const clearTreatmentsiteHandler = (e) => {
      if(e === 'pharmacy') {
        this.props.SettingsForm.values.user.buvidal.clinicPracticeName = ''
        this.props.SettingsForm.values.user.buvidal.AddressLine_1 = ''
        this.props.SettingsForm.values.user.buvidal.AddressLine_2 = ''
        this.props.SettingsForm.values.user.buvidal.clinicPracticeName = ''
        this.props.SettingsForm.values.user.buvidal.suburb = ''
        this.props.SettingsForm.values.user.buvidal.postcode = ''
        this.props.SettingsForm.values.user.buvidal.state = ''
        this.props.SettingsForm.values.user.buvidal.phone = ''
        this.props.SettingsForm.values.user.buvidal.specialDeliveryInstructions = ''
        this.props.SettingsForm.values.user.buvidal.hcl_account_number = ''
      }
    }

    const communications = [
      { value: 'email', label: <strong>{this.props.user.email}</strong> },
      { value: 'mobile-number', label: <strong>{this.props.user.mobileNumber}</strong> },
    ];

    const order = [
      { value: 'direct', label: 'Ship drug orders directly to my clinic / practice' },
      { value: 'pharmacy', label: 'I have made arrangements with a local pharmacy to receive orders on my behalf' },
    ]

    const dontKnowNumber = [
      {value: 'dont-know', label: 'I do not know the account number'}
    ]

    let treatmentSiteOptions = [...this.props.treatmentSites];
    if (!treatmentSiteOptions.length) {
      treatmentSiteOptions = [{ value: -1, label: 'My site is not listed' }];
    }

    const {order_shipping, additionalContact_role, hcl_account_number, symbion_account_number, treatmentSite, dontKnowNumber: dontKnowNumber_value} = this.props.SettingsForm && this.props.SettingsForm.values && this.props.SettingsForm.values.user && this.props.SettingsForm.values.user.buvidal ?  this.props.SettingsForm.values.user.buvidal : {}
    const dontKnowNumberSelected = !!(dontKnowNumber_value || []).length

    const accountNotFound = !(this.props.SettingsForm && this.props.SettingsForm.active && this.props.SettingsForm.active.includes('_account_number')) 
                            && ((order_shipping === 'pharmacy' && symbion_account_number) || (order_shipping === 'direct' && hcl_account_number)) 
                            && !treatmentSite;

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
            <input hidden autoComplete="off" />
            <h1>Buvidal profile settings</h1>
            <h2>Drug shipment and urgent program updates</h2>
            <p>Please select your preferred option for notifications. For urgent communications, we will use both email and mobile numbers as required.</p>
            <Checkbox styling="float" name="user.buvidal.communications" options={communications} validate={[required]} />
            <p>Incorrect details? Update your <NavLink to="/my-account/">MedAX account</NavLink>.</p>

            <h2>Ordering Initiation Packs</h2>
            <p>Participation in the PFP requires ordering a Buvidal<sup>®</sup> Weekly Initiation Pack. Initiation Packs include:</p>
            <ul>
              <li>1x Buvidal<sup>®</sup> Weekly 16 mg</li>
              <li>2x Buvidal<sup>®</sup> Weekly 8 mg</li>
            </ul>
            <p>The pack must be allocated to an <strong>enrolled patient</strong> before a new Initiation Pack can be ordered.</p>
            <p>Please confirm where drug orders should be shipped to. Please note that clinics/practices require a HCL account approved for Buvidal<sup>®</sup> orders; pharmacies require a Symbion account approved for Buvidal<sup>®</sup> orders.</p>
            <Checkbox styling="float" radio name="user.buvidal.order_shipping" options={order} validate={[required]} onChange={clearTreatmentsiteHandler} />
            
            {order_shipping && 
            <>
              <h2>Drug order account</h2>
              <p>Please confirm the Sold-to Account Number (HCL for treatment sites; Symbion for pharmacies)</p>
              {order_shipping === 'direct' &&
                <Field styling="float l" name="user.buvidal.hcl_account_number" type="text" title="HCL account number" onChange={()=>HCLOrPharmacyNumSearch(this, 'SettingsForm')} validate={dontKnowNumberSelected ? [] : [required]} />          
              }
              {order_shipping === 'pharmacy' &&
                <>
                  <Field styling="float l" name="user.buvidal.symbion_account_number" type="text" title="Symbion account number" onChange={()=>HCLOrPharmacyNumSearch(this, 'SettingsForm')} validate={dontKnowNumberSelected ? [] : [required]} />
                  <Field styling="float r" name="user.buvidal.pharmacy" type="text" title="Pharmacy" disabled/>          
                </>
              }
              {accountNotFound && 
                <p className='warning'>Account number not found. Please complete the Treatment Site Details below and contact the PFP Administrator for assistance on 1300 862 994</p>
              }
            </>
            }

            <h2>Treatment site</h2>
            <p>If Initiation Packs are being shipped directly to your practice, please review and confirm the following as your shipping address. Be sure to include any special delivery instructions.</p>
            <p>If Initiation Packs are being shipped to a pharmacy, even one that is co-located with your practice, please confirm where treatment will be administered.</p>
            <Field name="user.buvidal.clinicPracticeName" type="text" title="Clinic/Practice name" validate={[required]}/>
            <Field styling="float l" name="user.buvidal.AddressLine_1" type="text" title="Address Line 1" validate={[required]}/>
            <Field styling="float r" name="user.buvidal.AddressLine_2" type="text" title="Address Line 2" />          
            <Field styling="float l" name="user.buvidal.suburb" type="text" title="Suburb" validate={[required]}/>
            <Field styling="float r" name="user.buvidal.postcode" type="number" title="Postcode" validate={[required]}/>          
            <Select styling="float l" options={state} name="user.buvidal.state" type="text" title="State" validate={[required]}/>          
            <Field styling="float r" name="user.buvidal.phone" type="number" title="Phone" validate={[required]}/>          
            {order_shipping !== 'pharmacy' && <Field name="user.buvidal.specialDeliveryInstructions" type="text" title="Special delivery instructions" />}    

            {order_shipping === 'direct' &&
            <>
              <h2>Drug shipment contact</h2>
              <p>Please supply the contact details of the person responsible for receiving drug orders.</p>
              <Field styling="float l" name="user.buvidal.additionalContact_firstName" type="text" title="First name" validate={[required]} />
              <Field styling="float r" name="user.buvidal.additionalContact_lastName" type="text" title="Last name" validate={[required]} />
              <Field styling="float l" name="user.buvidal.additionalContact_emailAddress" type="email" title="Email address" autoCapitalize="none" autoCorrect="none" validate={[required, email]} />
              <Field styling="float r" name="user.buvidal.additionalContact_mobileNumber" type="tel" title="Mobile number" validate={[required, mobile]} />
              <Select styling="float l" options={additonalContactRole} name="user.buvidal.additionalContact_role" type="text" title="Role" validate={[required]} />
              {additionalContact_role === 'Other' &&
                <Field styling="float r" name="user.buvidal.additionalContact_specifyRole" type="text" title="Specify role" />            
              }
              <p>The additional contact must be a member of the healthcare team at the approved site who can receive updates on patient drug shipments (this person will not have access to the MedAX portal).</p>
            </>
            }

            <div className="navigation-profile-settings">
              <div className="primary">
                <button className="button-type-1">Save updates</button>
              </div>
              <div className="secondary">
                <NavButton to="/buvidal/settings/deregister-profile" className="button-type-3">De-register profile</NavButton>
              </div>
            </div>
          </form>
        </div>
      </main>
    )
  }
}

Settings = reduxForm({
  form: 'Settings' // a unique name for this form
})(Settings);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
import React, { Component } from "react";
import Sidebar from "../components/Sidebar"
import DrugOrderDetails from './components/DrugOrderDetails'

class ModifyOrder extends Component {
  render() {

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <DrugOrderDetails {...this.props} />
      </main>
    )
  }
}


export default ModifyOrder;
import React, { useState, useRef } from "react";
import styled from "styled-components";

const Content = styled.div``;

const Button = styled.button`
  border: 1px solid #47B4F5;
  border-radius: 4.71px;
  color: #47B4F5;
  width: 100%;
  background: white;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
  margin-bottom: 40px;
`;

const ShowMoreLess = (props) => {
  const [show, setShow] = useState(false);
  const buttonRef = useRef()

  const clickHandler = () => {
    setShow(!show)

    if(show) {
      setTimeout(() => {
        window.$('html, body').animate({
          scrollTop: buttonRef.current.offsetTop - (window.outerHeight/2)
        });
      }, 1)
    }
  }

  return (
    <>
      <Content>{show && <>{props.children}</>}</Content>
      <Button onClick={clickHandler} ref={buttonRef}>{show ? 'Show less' : 'Click here to read more about the study'}</Button>
    </>
  );
};

export default ShowMoreLess;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class CompanyMenu extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <div className="company-menu">
                <div className="wrapper-w880">
                    <ul className="company-menu-link">
                        <li><NavLink activeClassName="current" to="/company/about">About MedAX</NavLink></li>
                        <li><NavLink activeClassName="current" to="/company/privacy">Privacy Statement</NavLink></li>
                        <li><NavLink activeClassName="current" to="/company/terms">Terms of Use</NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default CompanyMenu;
const HCLOrPharmacyNumSearch = (self, form) => {
  setTimeout(() => {
    const isPharmacy =
      self.props[form].values.user.buvidal.order_shipping === "pharmacy";
    const accountNumField = isPharmacy
      ? "symbion_account_number"
      : "hcl_account_number";
    let value = self.props[form].values.user.buvidal[accountNumField];
    let site = self.props.treatmentSites.find(e=>e.Account_number == value && e.account_type === (isPharmacy ? 'Symbion' : 'HCL'));
    if (site) {
      self.props.change('user.buvidal.treatmentSite', site.id);
      if (isPharmacy) {
        self.props.change('user.buvidal.pharmacy', site.name);
      } else {
        const fields = [
          {userKey: 'clinicPracticeName', siteKey : 'name',},
          {userKey: 'AddressLine_1', siteKey : 'Address_1',},
          {userKey: 'AddressLine_2', siteKey : 'Address_2',},
          {userKey: 'suburb', siteKey : 'Suburb',},
          {userKey: 'postcode', siteKey : 'Postcode',},
          {userKey: 'state', siteKey : 'State',},
          {userKey: 'phone', siteKey : 'Phone',},
          {userKey: 'specialDeliveryInstructions', siteKey : 'Special_Shipping_Instructions'},
        ];

        for (const field of fields) {
          self.props.change('user.buvidal.' + field.userKey, site[field.siteKey]);
        }
      }

    } else {
      self.props.change('user.buvidal.treatmentSite', false);
      self.props.change('user.buvidal.pharmacy', '');
    }
  },1);
}

export default HCLOrPharmacyNumSearch;

import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Sidebar from "../components/Sidebar"
import { Field, Select } from "components/Forms"
import { gender, treatmentStablised, drugAddiction, durationOfInitialTreatment, patientStatus, previousTreatment } from "utils/selectValues";
import getQueryParams from "utils/getQueryParams";
import Navigation from "../components/Navigation"

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient => patient.id == id)[0];
  } else {
    patient = null;
  }

  return {
    patient,
    PatientDetailsForm: state.form.PatientDetails,
    initialValues: {
      user: {
        patient
      }
    },
    treatmentSites: state.user.treatmentSites ? state.user.treatmentSites.map(site => ({ value: site.id, label: site.name })) : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

class PatientDetails extends Component {
  render() {

    const {patient} = this.props.PatientDetailsForm && this.props.PatientDetailsForm.values && this.props.PatientDetailsForm.values.user ? this.props.PatientDetailsForm.values.user : {}
    const {drugAddiction: drugAddiction_value} = patient || []
    const showOtherAddicition = drugAddiction_value && drugAddiction_value.includes('other')
    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <h1>Patient details</h1>
          <form autoComplete="off">
            <input hidden autoComplete="off" />
            <Select disabled styling="float l" name="user.patient.treatmentSiteId" type="text" title="Treatment site" options={this.props.treatmentSites} />
            <Field disabled styling="float r" name="user.patient.initials" type="text" title="Initials" maxlength={4} />
            <Select disabled date disableCalendar isClearable styling="float l" name="user.patient.dob" type="text" title="Date of birth (DD/MM/YY)" noOptionsMessage={() => null} />
            <Select disabled styling="float r" name="user.patient.sex" type="text" title="Sex" options={gender} />
            <Select disabled isMulti styling="float l" name="user.patient.drugAddiction" type="text" title="Primary drug of addiction" options={drugAddiction} />
            {showOtherAddicition && <Field disabled styling="float r" name="user.patient.drugAddiction_other" type="text" title="Other primary drug of addiction" />}
            <Select disabled styling={`float ${showOtherAddicition ? 'l' : 'r'}`} name="user.patient.status" type="text" title="Patient status" options={patientStatus} />
            {patient && patient.status === 'Returning to treatment' && <Select disabled styling="float r" name="user.patient.previousTreatment" type="text" title="Previous treatment" options={previousTreatment} />}
            <Navigation secondaryText="Back" secondaryLink={"/buvidal/patients"} primaryText="" primaryLink="" />
          </form>
        </div>
      </main>
    )
  }
}

PatientDetails = reduxForm({
  form: 'PatientDetails' // a unique name for this form
})(PatientDetails);

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
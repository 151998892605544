import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class ContainerBox extends Component {
  render() {
    return (
        <main className={"accounts " + this.props.type}>

            <div className="box-container">
                <div className="box-wrap">
                    <div className="box">
                        <div className={"loader " + (this.props.isLoading?"show":"")}>
                            <img className="spinner" src={require('../../../img/accounts/Spinner-1s-200px.svg')} alt="spinner"/>
                        </div>

                        <img className="logo" src={require('../../../img/medax/medax_logo.png')} alt="medax-logo"/>
                        <h1>{this.props.title}</h1>
                        {this.props.children}
                    </div>
                    <div className="links" style={{
                        display:(this.props.noLinks?"none":"block"),
                    }}>
                        <div className="left">
                            <NavLink to="/company/about" target="_blank">About MedAX</NavLink>
                        </div>
                        <div className="right">
                            <NavLink to="/company/privacy" target="_blank">Privacy Statement</NavLink>
                            <NavLink to="/company/terms" target="_blank">Terms of Use</NavLink>
                        </div>
                    </div>
                </div>

            </div>

            <div className="copyright">
                <div className="copyright-text">N of One Solutions ABN 17 627 152 014 Enterprise 1, Suite 1, University of Wollongong Innovation Campus, Wollongong NSW 2500</div>
            </div>
        </main>
    )
  }
}


export default ContainerBox;
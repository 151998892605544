import React, { Component } from "react";
import NavButton from "components/NavButton";
import { NavLink } from "react-router-dom";
import moment from 'moment';
import capitalise from 'utils/capitalise';


function statusStage(value) {
  switch (value) {
    case 'active': return <div className="status active"><span>Active</span></div>;
    case 'unmanaged': return <div className="status active"><span>Managed</span></div>;
    case 'incomplete': return <div className="status incomplete"><span>Incomplete</span></div>;
    case 'discontinued': return <div className="status discontinued"><span>Discontinued</span></div>;
    case 'did-not-proceed': return <div className="status confirm-order"><span>Did not proceed</span></div>;
    case 'adverse-event': return <div className="status suspended"><span>Adverse event</span></div>;
    case 'confirm': return <div className="status confirm-order"><span>Confirm Order</span></div>;
    case 'suspended': return <div className="status suspended"><span>Suspended</span></div>;
  }
}

function regimeItem(size, regime, value){
  if (value) {
    return <li className={size}><span className="regime">{regime + ": "}</span>{value}</li>;
  } else {
    return <li className={size}><span className="regime">{regime + ": "}</span><span className="empty"></span></li>
  }
}

function formatDate(date) {
  if (!date) {
    return "";
  }
  return moment(date).format("DD/MM/YYYY");
}

class PatientBox extends Component {
  render() {

    let patient = {...this.props.patient};
    let adminDose = patient.oldDose && moment(patient.administrationDate).isBefore(moment(patient.shipmentDate), 'd') ? patient.oldDose : patient.orderQuantity;

    return (
      <div className={"patient-box" + ((this.props.type === "loading")?" loading":"")}>
        <div className="patient-details">
          <div className="name">{patient.initials}</div>
          {statusStage(patient.state)}
        </div>
        <div className={"regime-details " + (patient.state === 'discontinued' || patient.state === 'suspended' || patient.state === 'unmanaged' ? 'faded' : '')}>
          <ul>
            {regimeItem("double", "dob", formatDate(patient.dob))}
            {regimeItem("double", "sex", <span style={{textTransform: 'capitalize'}}>{patient.sex}</span>)}
          </ul>

          {/* Implement both - Required for responsive design */}
          <PatientBoxNavigation patient={patient} status={this.props.patient.state} />
        </div>
        <PatientBoxNavigation patient={patient} status={this.props.patient.state} />

        {/* {patient.state === 'confirm' ?
          <div className="patient-box-footer patient-confirm-order">
            <div className="left"><h3>Confirmation for your next order is required!</h3></div>
            <div className="right"><NavButton to={"/buvidal/patients/confirm-order/?patientId=" + patient.id} className="button-type-2">Confirm drug order</NavButton></div>
          </div>
          : ""}

        {patient.isConfirmed ?
          <div className="patient-box-footer patient-confirmed">
            <div className="left">
              <h3>Your next shipment has been confirmed.</h3>
              <p>If you would like to make any modifications please <NavLink to='/buvidal/contact-us'>contact us</NavLink>.</p>
            </div>
          </div>
          : ""}

        {patient.state === 'suspended' ?
          <div className="patient-box-footer patient-suspended">
            <div className="left">
              <h3>As your next shipment was not confirmed this account has been suspended.</h3>
              <p>If you would like to make any modifications please <NavLink to='/buvidal/contact-us'>contact us</NavLink>.</p>
            </div>
          </div>
          : ""} */}

      </div>
    )
  }
}

class PatientBoxNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      additionalMenu: false,
    };
  }

  toggleAdditionalMenu = () => {
    this.setState((prevState) => { return { ...prevState, additionalMenu: !prevState.additionalMenu } });
  }

  render() {
    let totalOrderQuantity = parseInt('' + this.props.patient.totalAdditional8mgUnits) * 8 + parseInt('' + this.props.patient.orderQuantity);
    let isMaxOrderReached = false;
    if (this.props.patient.orderQuantity < 64) {
      if (totalOrderQuantity >= 32) {
        isMaxOrderReached = true;
      }
    } else {
      if (totalOrderQuantity >= 128) {
        isMaxOrderReached = true;
      }
    }


    switch (this.props.status) {
      case "incomplete":
        return (
          <div className="patient-navigation">
            <button className="modify-order button-type-2 text-button">Patient enrolment incomplete</button>
            <span className="additional-settings-wrapper">
              <button className={"additional-settings button-type-2" + (this.state.additionalMenu ? " active" : "")} onClick={() => this.toggleAdditionalMenu()}></button>
              <div className="additional-settings-menu" onMouseLeave={() => this.toggleAdditionalMenu()} style={
                {
                  opacity: ((this.state.additionalMenu) ? "1" : "0"),
                  pointerEvents: ((this.state.additionalMenu) ? "all" : "none"),
                  visibility: ((this.state.additionalMenu) ? "visible" : "hidden"),
                }
              }>
                <ul>
                  <li><NavLink to={"/buvidal/patients/discontinue-patient/?patientId=" + this.props.patient.id}>Discontinue patient</NavLink></li>
                </ul>
              </div>
            </span>
            <NavButton to={"/buvidal/patients/onboarding-enrol-patient/?patientId=" + this.props.patient.id} className="primary-button button-type-2">Continue enrolment</NavButton>
          </div>
        );
      case "discontinued":
      case "suspended":
      case "unmanaged":
        return (
          <div className="patient-navigation">
            <button className="modify-order button-type-2 discontinued">Modify order</button>
            <span className="additional-settings-wrapper">
              <button className="additional-settings button-type-2 discontinued"></button>
            </span>
            <span className="additional-buvidal-8mg-wrapper">
              <button className="primary-button button-type-2 discontinued">+ Buvidal Weekly 8mg</button>
            </span>
          </div>
        );
      default:         
        return (
        <div className="patient-navigation">
          <span className="additional-settings-wrapper">
            <button className={"additional-settings button-type-2" + (this.state.additionalMenu ? " active" : "")} onClick={() => this.toggleAdditionalMenu()}></button>
            <div className="additional-settings-menu" onMouseLeave={() => this.toggleAdditionalMenu()} style={
              {
                opacity: ((this.state.additionalMenu) ? "1" : "0"),
                pointerEvents: ((this.state.additionalMenu) ? "all" : "none"),
                visibility: ((this.state.additionalMenu) ? "visible" : "hidden"),
              }
            }>
              <ul>
                <li><NavLink to={"/buvidal/patients/patient-details/?patientId=" + this.props.patient.id}>Patient details</NavLink></li>
                <li><NavLink to={"/buvidal/patients/patient-did-not-proceed/?patientId=" + this.props.patient.id}>Patient did not proceed</NavLink></li>
                <li><NavLink to={"/buvidal/patients/patient-adverse-event/?patientId=" + this.props.patient.id}>Report adverse event</NavLink></li>
              </ul>
            </div>
          </span>
        </div>
      );
    }

  }
}

export default PatientBox;


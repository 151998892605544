export default function getQueryPrams() {
    let query = {};
    let queryString = window.location.search;
    if (window.location.search) {
      let values = {};
      var vars = queryString.split('?')[1].split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        values[pair[0]] = pair[1];
      }
      //window.history.replaceState(null, null, window.location.pathname);
      query = {...query, ...values};
    }   

    return query;
}
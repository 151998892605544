import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import Sidebar from "../components/Sidebar"
import { boolean } from "utils/selectValues";
import { Checkbox } from "components/Forms"
import Warning from "../components/Warning";

import { PatientDetailBox } from "../components/DetailBox"
import getQueryParams from "utils/getQueryParams";
import { requestPatchUser } from 'modules/user/actions';
import Navigation from "../components/Navigation";

import {_required} from "utils/validation"
let required = _required();
const requireYes = value => value !== 'false' ? undefined : 'You must select "Yes" to discontinue patient.';

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient=>patient.id == id)[0];
  } else {
    patient = null;
  }

  return {
    patient,
    confirmDiscontinue: state.form.AdverseEventPatient && state.form.AdverseEventPatient.values && state.form.AdverseEventPatient.values.confirmDiscontinue
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class AdverseEventPatient extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      this.props.requestPatchUser({
        data: {
          patient: {
            id: this.props.patient.id,
            state: 'adverse-event'
          }
        },
        callback: (result) => {
          if (!result.error) {
            this.props.history.push('/buvidal/patients/');
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {

    if (!this.props.patient) {
      return "No patient selected";
    }

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
            <h1>Report adverse event</h1>
            <h2>For patient</h2>
            <PatientDetailBox patient={this.props.patient} />

            <h2>Adverse event reporting</h2>
            <p>Healthcare professionals participating in the Start and Stabilise Buvidal<sup>®</sup> Weekly PFP are expected to report to Camurus Australia, at <a href='mailto:safety@camurus.com'>safety@camurus.com</a>, any suspected adverse events and other safety findings including occupational exposure, off-label use, lack of efficacy, interactions, suspicion of counterfeit product, adverse reactions including those related to quality defect; suspected transmission of an infectious agent by a medicinal product, overdose, misuse, abuse, medication errors, loss of effect, pregnancy and lactation exposure, and any unexpected therapeutic benefit. Please note that Camurus Drug Safety partners may contact you to follow up if clarification of the spontaneous report is required.</p>
            <p>Healthcare professionals are also encouraged to report any adverse events occurring during the Start and Stabilise Buvidal<sup>®</sup> Weekly PFP to the TGA at <a target='_blank' rel="noopener noreferrer" href='https://www.tga.gov.au/reporting-problems'>www.tga.gov.au/reporting-problems</a>.</p>
            <p><strong>Product quality complaints (PQC)</strong></p>
            <p>Product quality complaints (PQCs) relates to the appearance and usability of the product. Any PQC that relates to labeling, appearance, or technical aspects should be reported within 1 working day to <a href='mailto:complaints@camurus.com'>complaints@camurus.com</a>. The report should include details of the event as well as product name, strength and batch number in order to enable proper investigation of the PQC. If possible, the complaint sample should be safely stored until further notice from the company.</p>

            <h2>Confirmation</h2>
            <Checkbox validate={[required, requireYes]} radio title="Please confirm that you have reported the adverse event and / or product complaint to Camurus Drug Safety:" styling="float r" name="confirmDiscontinue" options={boolean} />

            <Navigation secondaryText="Back" secondaryLink={"/buvidal/patients"} primaryText="Confirm" primaryLink="" />
          </form>
        </div>
      </main>
    )
  }
}

AdverseEventPatient = reduxForm({
  form: 'AdverseEventPatient' // a unique name for this form
})(AdverseEventPatient);

export default connect(mapStateToProps, mapDispatchToProps)(AdverseEventPatient);
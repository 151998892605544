import React, { Component } from "react";
import NavButton from "components/NavButton";

class Navigation extends Component {
  render() {
    return (
      <div className="navigation">
        <div className="secondary">
          {
            this.props.secondaryLink &&
            <a onClick={this.props.secondaryLink}>{this.props.secondaryText}</a>
          }
        </div>
        {
          (this.props.external) ?
            <div className="primary">
              <a href={this.props.target} target="_blank" className="button-type-1">{this.props.primaryText}</a>
            </div>
            :
            <div className="primary">
              <NavButton type="submit" className="button-type-1" to={this.props.primaryLink} target={this.props.target} onClick={this.props.primaryOnClick}>{this.props.primaryText}</NavButton>
            </div>
        }

      </div>
    );
  }
}

export default Navigation;
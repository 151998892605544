import React, { Component } from "react";
import NavButton from "components/NavButton";

class Navigation extends Component {
  render() {
    return (
      <div className="navigation">
        {this.props.secondaryText &&
          <NavButton className="button-type-2" to={this.props.secondaryLink}>{this.props.secondaryText}</NavButton>        
        }
        {this.props.primaryText &&
          <button type="submit" className="button-type-1">{this.props.primaryText}</button>
        }
      </div>
    )
  }
}

export default Navigation;
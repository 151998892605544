import React, { Component } from "react";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openModal } from 'modules/modals/actions';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
  }, dispatch);
}

class ExternalLink extends Component {
  render() {
    let props = {...this.props};
    delete props.href;
    delete props.children;

    return (
      <a {...props} onClick={()=>this.props.openModal({modal: "LeaveSite", data: this.props.href})}>
        {this.props.children}
      </a>
    );
  }
}

export default connect(null, mapDispatchToProps)(ExternalLink);
import React, { Component } from "react";

class Warning extends Component {
    render() {
        return (
          <div className="warning">
            {this.props.children}
          </div>
        )
    }
}

export default Warning;
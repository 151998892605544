import React, { Component } from "react";
import Header from "components/Header";
import CompanyMenu from "./components/CompanyMenu";

class TermsOfUse extends Component {
    render() {
        return (
            <main className={"company " + this.props.type}>
            <CompanyMenu />
                <div className="content">
                    <div className="wrapper-w880">
                        <h1>MedAX Terms of Use</h1>
                        <p>Your access to this website is conditional on these Terms of Use.</p>

                        <h2>1. Restricted access</h2>

                        <p>Access to this site is restricted to AHPRA‐registered medical practitioners currently practicing in Australia. By registering, you confirm you meet the criteria for entry into this site. Unauthorised registrations may be deleted without notice.</p>

                        <h2>2. Medical advice</h2>

                        <p>The information on this site is not medical advice.</p>

                        <p>Medical professionals are expected to rely on their own professional skill, care and inquiries in the context of the clinical circumstances for each patient.</p>

                        <h2>3. Disclaimer and limitation of liability</h2>

                        <p>N of One Solutions and Wax Interactive disclaim all liability (including for negligence) for any loss, damage or injury resulting from reliance on or use of any information on this website.</p>

                        <p>Neither N of One Solutions or Wax Interactive nor any of their employees is liable for any loss, claim, damage, expense, injury, whether direct or indirect howsoever incurred, caused or contributed to by any person's use or misuse of the information available on this website or contained on any third-party website that is accessed through a link contained on this website.</p>

                        <h2>4. Intellectual property</h2>

                        <p>This website is protected by intellectual property laws (including copyright). You must not copy, republish, sell or redistribute or otherwise exploit the contents of this website without the prior written consent of N of One Solutions or Wax Interactive.</p>

                        <h2>5. Privacy</h2>

                        <p>This website is subject to our privacy statement.</p>

                        <h2>6. General</h2>

                        <p>This website is intended for the use of medical professionals resident in Australia. Use of this website, and these Terms of Use, are subject to the laws of New South Wales, Australia.</p>

                        <p>N of One Solutions and Wax Interactive may amend these Terms of Use at any time.</p>
                    </div>
                </div>
            </main>
        )
    }
}

export default TermsOfUse;
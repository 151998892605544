import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import moment from 'moment'

import Sidebar from "../components/Sidebar"
import { NavLink } from "react-router-dom";
import { additionalDrugs } from "utils/selectValues";
import { Checkbox } from "components/Forms"
import Navigation from "../components/Navigation";

import { ActivePatientDetailBox } from "../components/DetailBox"
import getQueryParams from "utils/getQueryParams";
import { requestPatchUser } from 'modules/user/actions'
import {_required, _email} from "utils/validation"

import getCurrentDate from "utils/getCurrentDate"
import deliveryTimes from "utils/deliveryTimes"

let required = _required();

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient=>patient.id == id)[0];
  } else {
    patient = null;
  }

  return {
    patient,

    initialValues: {
      user: {
        patient: {
          additional8mgUnitsOrdered: '' + (patient?patient.additional8mgUnitsOrdered:'')
        }
      }
    },
      isRural: patient && patient.treatmentSiteId && state.user.treatmentSites.filter(site => site.id === patient.treatmentSiteId)[0].delivery === 'rural',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class OrderExtra extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      let shipmentDate = getCurrentDate();
      if (shipmentDate.hour() >= 12) {
        shipmentDate.add(1, 'd');
      }
      while (shipmentDate.day() >= 5 || shipmentDate.day() === 0) {
        shipmentDate.add(1, 'd');
      }

      results.user.patient.additional8mgShipmentDate = shipmentDate.format('YYYY-MM-DD');
      results.user.patient.id = this.props.patient.id;

      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push({
              pathname: "/buvidal/patients/order-extra-confirm/?patientId=" + this.props.patient.id,
            });
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {

    if (!this.props.patient) {
      return "";
    }

    if (!this.props.patient.treatmentSite.allowAdditional) {
      this.props.history.push('/buvidal/patients/order-extra-out-of-stock');
    }

    let maxOrder;

    if (this.props.patient.orderQuantity < 64) {
      maxOrder = Math.floor((64 - this.props.patient.orderQuantity * 2) / 8);
    } else {
      maxOrder = Math.floor((128 - this.props.patient.orderQuantity) / 8);
    }

    maxOrder -= parseInt('' + this.props.patient.totalAdditional8mgUnits) || 0;

    if (maxOrder < 1) {
      return (
        <main className={"dashboard content-page " + this.props.type}>
          <Sidebar />
          <div className="content">
            <h2>Max dosage ordered for patient</h2>
          </div>
        </main>
      );
    }

    if (this.props.patient.additional8mgShipmentDate && this.props.patient.additional8mgOrderConfirmedDate === this.props.patient.additional8mgShipmentDate) {
      return (
        <main className={"dashboard content-page " + this.props.type}>
          <Sidebar />
          <div className="content">
            <h2>Your order has been submitted</h2>
          </div>
        </main>
      );
    }

    let radioValues = additionalDrugs.slice(0, maxOrder);

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
            <h1>Order Buvidal Weekly 8 mg</h1>
            <p>Please review the full Product Information (PI) for both Buvidal Weekly and Buvidal Monthly before prescribing. Full PIs can be accessed <NavLink to="/buvidal-patient-resources" target="_blank">here</NavLink>.</p>

            <h2>Patient</h2>
            <ActivePatientDetailBox patient={this.props.patient} />

            <h2>Dose adjustment</h2>
            <p>An additional Buvidal Weekly 8 mg injection may be given during a dosing period, if required, up to a maximum dose of:</p>
            <ul>
              <li>32 mg for the weekly injections</li>
              <li>128 mg for the monthly injections</li>
            </ul>

            <h2>Order quantity</h2>
            <h3>Additional Buvidal Weekly 8 mg</h3>
            <Checkbox validate={[required]} radio styling="float" name="user.patient.additional8mgUnitsOrdered" options={radioValues} />

            <p className="small">For orders placed <strong>before 12pm Mon-Thu </strong>(AEST) earliest delivery will be <strong>+{(this.props.isRural ? deliveryTimes.rural: deliveryTimes.urban)}  business day</strong>.</p>
            <p className="small">For orders placed <strong>after 12pm Mon-Wed </strong>(AEST) earliest delivery will be <strong>+{(this.props.isRural ? deliveryTimes.rural: deliveryTimes.urban) + 1} business days</strong>.</p>
            <p className="small">For orders placed <strong>after 12pm Thu or anytime Fri-Sun</strong> (AEST) earliest delivery will be the <strong>following {this.props.isRural ? deliveryTimes.ruralAfterThurs : deliveryTimes.urbanAfterThurs}</strong>.</p>
            <p className="extra-small grey">Note: Administration date must be at least 1 day after earliest delivery date.</p>

            {/* <p>For orders placed before <strong>12pm Mon-Thu</strong> (AEST) shipping will take <strong>2 business days</strong>.</p>
            <p>For orders placed after <strong>12pm Mon-Thu</strong> (AEST) shipping will take <strong>3-4* business days</strong>.</p>
            <p className="small grey">*Orders submitted after 12pm Thursday or Friday to Sunday (AEST) will be shipped the following Monday.</p> */}

            <Navigation secondaryText="Back" secondaryLink={"/buvidal/patients"} primaryText="Continue" primaryLink="/buvidal/patients/order-extra-confirm" />
          </form>
        </div>
      </main>
    )
  }
}

OrderExtra = reduxForm({
  form: 'OrderExtra' // a unique name for this form
})(OrderExtra);

export default connect(mapStateToProps, mapDispatchToProps)(OrderExtra);
import React, { Component } from "react";
import ContainerBox from "./components/ContainerBox";
import NavButton from "components/NavButton";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  signupStage: state.user.signupStage,
  email: state.user.email
});

class SigninContinueRegistration extends Component {
  render() {
    let linkAddress = '';
    if (this.props.signupStage === 0) {
      linkAddress = '/signup-confirm-email';
    } else if (this.props.signupStage === 1) {
      linkAddress = '/signup-enter-mobile';
    } else if (this.props.signupStage === 2) {
      linkAddress = '/signup-complete-account';
    }

    return (
      <ContainerBox type="signin-continue-registration" title="Continue registration">
        <p>Welcome back <strong className="blue">{this.props.email}</strong>. There are a few steps to complete before your account is created.</p>
        <img className="main-image" src={require('../../img/accounts/continue_registration.png')} alt="continue_registration" />
        <div className="navigation">
          <div className="secondary">
          </div>
          <div className="primary">
            <NavButton to={linkAddress} className="button-type-1">Continue</NavButton>
          </div>
        </div>

      </ContainerBox>
    )
  }
}

export default connect(mapStateToProps, null)(SigninContinueRegistration);
import React, { Component } from "react";

import WeeklyCMI from 'pdf/Buvidal Weekly CMI.pdf'
import MonthlyCMI from 'pdf/Buvidal_Monthly_CMI.pdf'
import PdfDocument from "./components/PdfDocument"
import PatientEducationBooklet from 'pdf/AU-BUV-2100041_Buvidal-Patient-educational-material.pdf'
import PatientAlertCard from 'pdf/AU-BUV-210004_Buvidal-Patient-Alert-Card.pdf'
import { DocumentName } from "./Resources";
class Patients extends Component {
  render() {
    return (
      <main className={"dashboard content-page " + this.props.type}>
        <div className="content">
          <h1>Patient resources</h1>
          <PdfDocument documentName={<DocumentName>Buvidal<sup>®</sup> Weekly Consumer Medicines Information (CMI)</DocumentName>} link={WeeklyCMI} />
          <PdfDocument documentName={<DocumentName>Buvidal<sup>®</sup> Monthly Consumer Medicines Information (CMI)</DocumentName>} link={MonthlyCMI} />
          <PdfDocument documentName="Patient Alert Card" link={PatientAlertCard} />
          <PdfDocument documentName="Patient education booklet" link= {PatientEducationBooklet} />
        </div>
      </main>
    )
  }
}

export default Patients;
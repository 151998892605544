import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';

function mapStateToProps(state) {
  return {
    user: state.user,
    router: state.router
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}


class IncorrectSpecialisation extends Component {
  notify() {
    this.props.requestPatchUser({data: {meta: {alertPreferences: "true"}}, callback: ()=>{}});
  }
  render() {
    return (
      <main className={"company " + this.props.type}>
        <div className="content">
          <div className="wrapper-w880">
            <h1>Thank you</h1>
            <p>Thank you for registering your interest in the MedAX Medication Access Portal.</p>
            <p>If you believe you have received this message in error, please contact us at <a href="medax@nof1solutions.com.au">medax@nof1solutions.com.au</a>.</p>
            <p>There are currently no programs available in your area that relate to your scope of practice and/or area of specialisation. </p>
            {(this.props.user.meta.alertPreferences !== 'true')?[
              <p>If you would like to receive notifications when relevant programs are available, please click ‘Notify me’.</p>,
              <br />,
              <button className="button-type-1" onClick={()=> this.notify()}>Notify me</button>
              ]
            :
              <p>We will notify you when programs are available in your area. You can modify your notification preferences under your account.</p>
            }
          </div>
        </div>
      </main>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncorrectSpecialisation);
import { createAction } from 'redux-actions';

export const REQUEST_SIGNUP = 'REQUEST_SIGNUP';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const RECEIVE_AUTH = 'RECEIVE_AUTH';
export const REQUEST_PATCH_USER = 'REQUEST_PATCH_USER';
export const RECEIVE_PATCH_USER = 'RECEIVE_PATCH_USER';

export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_TOKEN_LOGIN = 'REQUEST_TOKEN_LOGIN';
export const REQUEST_SECURE_LINK = 'REQUEST_SECURE_LINK';
export const REQUEST_VERIFICATION = 'REQUEST_VERIFICATION';
export const REQUEST_VALIDATION_MESSAGE = 'REQUEST_VALIDATION_MESSAGE';

export const requestSignup = createAction(REQUEST_SIGNUP);
export const requestLogin = createAction(REQUEST_LOGIN);
export const requestAuth = createAction(REQUEST_AUTH);
export const requestLogout = createAction(REQUEST_LOGOUT);
export const receiveLogout = createAction(RECEIVE_LOGOUT);
export const receiveAuth = createAction(RECEIVE_AUTH);
export const requestPatchUser = createAction(REQUEST_PATCH_USER);
export const receivePatchUser = createAction(RECEIVE_PATCH_USER);

export const requestForgotPassword = createAction(REQUEST_FORGOT_PASSWORD);
export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET);
export const requestTokenLogin = createAction(REQUEST_TOKEN_LOGIN);
export const requestSecureLink = createAction(REQUEST_SECURE_LINK);
export const requestVerification = createAction(REQUEST_VERIFICATION);
export const requestValidationMessage = createAction(REQUEST_VALIDATION_MESSAGE);


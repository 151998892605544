const createRegistrationLink = (user) => {
  const fields = [
    {'dbKey' : 'firstName', 'websiteKey' : 'first_name'},
    {'dbKey' : 'lastName', 'websiteKey' : 'last_name'},
    {'dbKey' : 'email', 'websiteKey' : 'user_email'},
    {'dbKey' : 'title', 'websiteKey' : 'prefix'},
    {'dbKey' : 'ahpra', 'websiteKey' : 'ahpra_number'},
  ];

  let queryString = '';
  if (user) {
      queryString = fields.map(field=>`${field.websiteKey}=${user[field.dbKey]}`).join('&');
      queryString += `&primary_clinic_postcode=${user.buvidal.postcode}&primary_clinic=${user.buvidal.clinicPracticeName}&primary_clinic_phone_number=${user.buvidal.phone}`
  }
  return queryString
}

export default createRegistrationLink
import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerBox from "view/accounts/components/ContainerBox";
import Navigation from "view/accounts/components/Navigation";
import { Field } from "components/Forms"
import { reduxForm, SubmissionError } from 'redux-form';
import { requestPatchUser } from 'modules/user/actions'

import formatMobileNumber from "utils/formatMobileNumber"
import {_required, _mobile} from "utils/validation"
let required = _required();
let mobile = _mobile();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class ChangeMobile extends Component {

  submit(results) {
    results.user.mobileNumber = formatMobileNumber(results.user.mobileNumber);
    return new Promise((resolve, reject) => {
      this.props.requestPatchUser({
        data: results.user,
        waitForResult: true,
        callback: (result)=>{
          if (!result.error) {
            this.props.history.push('/my-account/change-mobile-confirm');
            return resolve();
          } else {
            if (result.message === 'SMS Limit Exceeded') {
              this.props.history.push('/my-account/too-many-sms-attempts');
            }
            return reject(new SubmissionError({
              ...result.validation
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="my-account  account-change-mobile" title="Change mobile" noLinks isLoading={this.props.submitting}>
        <p>To change your mobile please enter your mobile number.</p>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <Field name="user.mobileNumber" type="tel" title="Your mobile number" autoFocus validate={[required, mobile]} />
          <Navigation secondaryText="Back" secondaryLink="/my-account" primaryText="Next" primaryLink="/change-mobile-confirm" />
        </form>

      </ContainerBox>
    )
  }
}

ChangeMobile = reduxForm({
  form: 'ChangeMobile' // a unique name for this form
})(ChangeMobile);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeMobile);
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from "components/Header";

import RoutesLoggedOut from "view/RoutesLoggedOut";
import './App.css';
import { 
  requestAuth, 
  requestTokenLogin, 
  requestVerification,
  requestLogout,
  requestPatchUser } from 'modules/user/actions';
import { app } from "modules";
import getQueryParams from "utils/getQueryParams";
import ScrollToTop from 'view/ScrollToTop';
import RoutesAuthenticated from 'view/RoutesAuthenticated';
import { push } from 'connected-react-router'
import Modal from 'components/modals/components/Modal';
import moment from 'moment';
import lastAction from 'utils/lastAction'
import CamarusLogo from './img/medax/camurus-logo-webb-bliue-1.jpeg'
import BWPDF from './pdf/Buvidal Weekly Product Information.pdf'

function mapStateToProps(state) {
  return {
    user: state.user,
    router: state.router,
    currentModal: state.modals.currentModal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestAuth,
    requestTokenLogin,
    requestVerification,
    requestPatchUser,
    requestLogout,
    push
  }, dispatch);
}

function newNavigation(){

  if (window.performance.navigation) {
    return (window.performance.navigation.type === 0);
  }

  if (window.performance.getEntriesByType("navigation")){
     return window.performance.getEntriesByType("navigation")[0].type === 'navigate';     
  }

  return false;
}

class App extends Component {

  constructor() {
    super();
    this.state = {
      hasLoaded: false
    }
  }

  requestAuth(callback) {
    this.props.requestAuth({
      onSuccess: (user) => {
        if (!callback || !callback.doNotSetLoaded) {
          this.setState((prevState) => {
            return {...prevState, hasLoaded: true}
          });
        }
        
        callback && callback.onSuccess && callback.onSuccess(user);
      },
      onFail: (result)=>{
        if (!callback || !callback.doNotSetLoaded) {
          this.setState((prevState) => {
            return {...prevState, hasLoaded: true}
          });
        }
        callback && callback.onFail && callback.onFail(result);
      }
    });
  }

  componentWillMount() {
    let queryParams = getQueryParams();

    if (queryParams.loginToken) {
      if (queryParams.type !== 'admin') {
        this.requestAuth({
          doNotSetLoaded: true,
          onSuccess: ()=>{
            this.setState((prevState) => {
              return {...prevState, hasLoaded: true}
            });
          },
          onFail: ()=>{
            this.props.requestTokenLogin({email: queryParams.email, userId: queryParams.userId, token: queryParams.loginToken, callback: (result)=>{
              if (!result.err) {
                this.requestAuth();
              }
            }});
          }
        });
      } else {
        this.props.requestTokenLogin({email: queryParams.email, token: queryParams.loginToken, callback: (result)=>{
          if (!result.err) {
            this.requestAuth();
          }
        }});
      }
    } else if (queryParams.type === "verify") {
      this.requestAuth({
        doNotSetLoaded: true,
        onSuccess: ()=>{
          this.props.requestVerification({
            email: queryParams.email, 
            token: queryParams.token,
            type: 'email',
            callback:(result)=>{
              this.setState((prevState) => {
                return {...prevState, hasLoaded: true}
              });
              if (result.error) {
                
              } else {
                if (!this.props.user.signupStage) {

                } else if (this.props.user.signupStage < 3) {
                  if (this.props.user.signupStage === 0) {
                    this.props.push('/signup-confirm-email');
                  } else if (this.props.user.signupStage === 1) {
                    this.props.push('/signup-enter-mobile');
                  } else if (this.props.user.signupStage === 2) {
                    this.props.push('/signup-complete-account');
                  }                  
                } else {
                  this.props.push('/my-account/profile-updated');
                }
              }
          }});
        },
        onFail: ()=>{
          this.setState((prevState) => {
            return {...prevState, hasLoaded: false}
          });
          this.props.requestTokenLogin({email: queryParams.email, token: queryParams.token, type: "validate-login", callback: ()=>{
            this.requestAuth();
          }});
        }
      });
    } else {
      if (newNavigation()) {
        this.requestAuth({
          doNotSetLoaded: true,
          onSuccess: ()=>{
            this.props.requestLogout({dontRedirect: true});
          },
          onFail: ()=>{
            this.setState((prevState) => {
              return {...prevState, hasLoaded: true}
            });
          }
        });
      } else {
        this.requestAuth();
      }
    }

    setInterval(() => {
      this.checkServerStatus();
    }, 5000);
  }

  async checkServerStatus () {
    if (this.state.hasLoaded && this.props.user.signupStage) {
      if (!app.passport.valid) {
        const token = await app.passport.getJWT();
        try {
          let userId = (await app.passport.verifyJWT(token)).userId;
          if (userId !== this.props.user.id) {            
            this.props.push('/session-expired');
            this.setState(state=>({...state, sessionExpired: true}));
          }
        } catch (e) {
          this.props.requestLogout({path: window.location.pathname.substr(1)});
        }
      }
      if (moment().diff(lastAction.get()) > 30 * 60 * 1000) {
        this.props.requestLogout({path: window.location.pathname.substr(1)});
      }
    }
  }

  headerType(page){
    let pagetype = page.substr(0, page.lastIndexOf("/"));

    if (pagetype.includes("company")) {
      return <Header />;
    } else {
      return "";
    }
  }

  render() {
    if (!this.state.hasLoaded) {
      return "";
    }

    if (this.state.sessionExpired) {
      return <h1 style={{'font-size': 40}}>Session expired</h1>;
    }


    if (this.props.user.signupStage === -1) {
      return "";
    }


    if (!this.props.user.signupStage || this.props.user.signupStage < 3) {
      return (
        <div>
          <Modal />
          {this.headerType(this.props.router.location.pathname)}
          <ScrollToTop>
            <RoutesLoggedOut signupStage={this.props.user.signupStage} />
          </ScrollToTop>
        </div>
      );
    }

    return (
      <div className={((this.props.currentModal)?"static":"")}>
        <Modal />
        <Header type="authenticated" />
        <ScrollToTop>
          <RoutesAuthenticated user={this.props.user} onboardingStep={(this.props.user.buvidal && this.props.user.buvidal.onboardingStep) || 1} />
        </ScrollToTop>
        {(this.props.router.location.pathname.substr(0, this.props.router.location.pathname.lastIndexOf("/")).includes("buvidal"))?
          <div className="bottom-mandatories">
            <div>
              <div className='top'>
                <span>
                  <a href={BWPDF} target='_blank' rel="noopener noreferrer">Click here</a> to access the Buvidal<sup>®</sup> Weekly Product Information
                </span>
              </div>
              <div className='bottom'>
                <img src={CamarusLogo} alt='Camurus' width='110'/>
                <span>Buvidal<sup>®</sup> is the registered trademark of Camurus Pty Ltd.  Camurus Pty Ltd. ABN 79 627 784 605. 223 Liverpool Street, Darlinghurst NSW 2010. Ph 1800 142 038. AU-BUV-2100058 Date of preparation: November 2021.</span>
              </div>  
            </div>
          </div>
          :""
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
import React, { Component } from "react";

import Sidebar from "../components/Sidebar"
import PatientProgressBar from "../components/PatientProgressBar";
import ConfirmOrder from "./components/ConfirmOrder";

class OnboardingConfirmOrder extends Component {
  render() {
    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <PatientProgressBar step="3" />
        <ConfirmOrder {...this.props} isOnboarding={true} />
      </main>
    )
  }
}

export default OnboardingConfirmOrder;
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Sidebar extends Component {

  componentDidMount() {
    // const mq = window.matchMedia( "(max-width: 960px)" );  // ID-SMALLSIDEBAR

    // // On component load - update menu
    // if (mq.matches) {
    //   window.$(".sidebar").addClass("small");
    //   window.$(".sidebar").removeClass("large");
    // } else {
    //   window.$(".sidebar").removeClass("small");
    //   window.$(".sidebar").addClass("large");
    // }

    // // On window resize - Change menu
    // window.$(window).resize(function() {
    //     if (mq.matches) {
    //       window.$(".sidebar").addClass("small");
    //       window.$(".sidebar").removeClass("large");
    //     } else {
    //       window.$(".sidebar").removeClass("small");
    //       window.$(".sidebar").addClass("large");
    //     }
    // });

    let documentClick = false;

    window.$(document).on('touchstart', function() {
      documentClick = true;
    });

    window.$(document).on('touchmove', function() {
      documentClick = false;
    });

    // If user's cursor in on the Menu
    window.$(".sidebar").hover(function() {
      if (!window.$(this).hasClass("large")) {
        window.$(this).removeClass("small");
        window.$(this).addClass("hover");
        window.$(this).addClass("large");
      }
    },
    function() {
      if (window.$(this).hasClass("hover")) {
        window.$(this).removeClass("hover");
        window.$(this).removeClass("large");
        window.$(this).addClass("small");
      }
    });
  }


  render() {
    return (
      <div className="sidebar large">
        <ul>
          <li><NavLink activeClassName="current" to="/buvidal/patients">Patients</NavLink></li>
          <li><NavLink activeClassName="current" to="/buvidal/resources">Resources</NavLink></li>
          <li><NavLink activeClassName="current" to="/buvidal/settings">Settings</NavLink></li>
          <li><NavLink activeClassName="current" to="/buvidal/contact-us">Contact us</NavLink></li>
          <li><NavLink activeClassName="current" to="/buvidal/terms-and-conditions">Terms & conditions</NavLink></li>
          <li><NavLink activeClassName="current" to="/buvidal/privacy-statement">Privacy statement</NavLink></li>
        </ul>
      </div>
    )
  }
}

export default Sidebar;
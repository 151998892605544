import React from "react";
import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { closeModal } from 'modules/modals/actions';
import Navigation from "./components/Navigation";

function mapStateToProps(state) {
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
  }, dispatch);
}

class ProductProfileUpdated extends Component {
  render() {
    if (this.props.currentModal === "ProductProfileUpdated") {
    return (
      <div className="modal-content">
        <h1>Program profile updated</h1>
        <p>Profile has been successfully updated.</p>
        <Navigation primaryText="Continue" primaryOnClick={() => this.props.closeModal()} />
      </div>
    ) } else {
      return "";
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductProfileUpdated);
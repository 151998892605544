import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';

import { requestPasswordReset } from '../../modules/user/actions'

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import { Field } from "components/Forms"
import getQueryParams from "utils/getQueryParams";
import {_required, _password} from "utils/validation"
let required = _required();
let password = _password();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPasswordReset,
  }, dispatch);
}

class ForgotPasswordChange extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    let values = getQueryParams(this.props.location.search);
    this.setState(state=>({...state, ...values}));
  }

  submit(results) {
    return new Promise((resolve, reject) => {
      if (results.password) {
        if (results.password !== results['password_confirm']) {
          return reject(new SubmissionError({
            'password_confirm': 'Passwords do not match'
          }));
        }
      }

      this.props.requestPasswordReset({
        data: {
          password: results.password,
          email: this.state.email,
          mobileNumber: this.state.mobile,
          token: this.state.pin
        },
        callback: (result) => {
          if (!result.error) {
            this.props.history.push('/');
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="forgot-password-change" title="Reset password" isLoading={this.props.submitting}>
        {this.state.firstName && 
          <p><strong className="blue">{this.state.firstName + ' ' + this.state.lastName}</strong></p>
        }
        <p>Please enter your new password.</p>

        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <Field name="password" type="password" title="Enter your new password" autoFocus validate={[required, password]} />
          <Field name="password_confirm" type="password" title="Confirm your password" validate={[required]} />
          <Navigation secondaryText="Back to Forgot Password" secondaryLink="/forgot-password" primaryText="Log in" primaryLink="/login" />
        </form>
      </ContainerBox>
    )
  }
}


ForgotPasswordChange = reduxForm({
  form: 'ForgotPasswordChange' // a unique name for this form
})(ForgotPasswordChange);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordChange);
import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerBox from "view/accounts/components/ContainerBox";
import Navigation from "view/accounts/components/Navigation";
import { Field } from "components/Forms"
import { reduxForm, SubmissionError } from 'redux-form';
import { requestPatchUser } from 'modules/user/actions'

import {_required, _email, _password} from "utils/validation"
let required = _required();
let password = _password();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class ChangePassword extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      if (results.password) {
        if (results.user.password !== results['password_confirm']) {
          return reject(new SubmissionError({
            'password_confirm': 'Passwords do not match'
          }));
        }
      }

      this.props.requestPatchUser({
        data: results.user,
        callback: (result)=>{
          if (!result.error) {
            this.props.history.push('/my-account/profile-updated');
            return resolve();
          } else {
            return reject(new SubmissionError({
              ...result.validation
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="my-account account-change-password" title="Change password" noLinks isLoading={this.props.submitting}>
        <p>To change your password please enter your current and new password.</p>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <Field name="user.currentPassword" type="password" title="Your current password" autoFocus  validate={[required]} />
          <Field name="user.password" type="password" title="Your new password" validate={[required, password]} />
          <Field name="password_confirm" type="password" title="Confirm new password" validate={[required]} />

          <Navigation secondaryText="Back" secondaryLink="/my-account" primaryText="Next" primaryLink="/signup-confirm-mobile" />
        </form>

      </ContainerBox>
    )
  }
}

ChangePassword = reduxForm({
  form: 'ChangePassword' // a unique name for this form
})(ChangePassword);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
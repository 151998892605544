import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: inline-flex;

  .right {
    margin-left: 30px;
  }

  @media(max-width: 1005px) {
    margin-bottom: 30px;
    margin-top: -15px;
  }

  @media (max-width: 640px) {
    margin-top: 0;
  }

  @media (max-width: 360px) {
    flex-direction: column;
    .right {
      margin-left: 0;
      margin-top: 20px;
    }
    .button-type-1  {
      width: 100%;
    }
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: 1005px) {
    flex-direction: column;
  }
`

export const TextBlue = styled.span`
  color: #47B4F5;
  font-weight: bold;
`
import React from "react";

import HCPEducationBooklet from 'pdf/AU-BUV-2100040_Buvidal-HCP-educational-material.pdf'
import PatientEducationBooklet from 'pdf/AU-BUV-2100041_Buvidal-Patient-educational-material.pdf'
import WeeklyCMI from 'pdf/Buvidal Weekly CMI.pdf'
import WeeklyPI from 'pdf/Buvidal Weekly Product Information.pdf'
import PatientAlertCard from 'pdf/AU-BUV-210004_Buvidal-Patient-Alert-Card.pdf'
import HCPGuide from 'pdf/HCP_Guide_DIG_LAYOUT.pdf'

const TermsAndConditionsContent = () => {
  return (
    <>
        <h1>
          Start and Stabilise with Buvidal<sup>®</sup> Weekly PFP Terms &
          Conditions
        </h1>
        <p>
          Prior to participating in this program, you are required to confirm
          the following:
        </p>
        <ul>
          <li>
            You are authorised to prescribe long-acting injectable
            buprenorphine formulations for the treatment of opioid dependence;
          </li>
          <li>
            You have received and read copies of the <a href={HCPEducationBooklet} target='_blank' rel="noopener noreferrer">TGA-approved healthcare
            professional (HCP) education booklet</a> AND the <a href={WeeklyPI} target='_blank' rel="noopener noreferrer">Buvidal<sup>®</sup> Weekly Product
            Information (PI)</a> and understand the appropriate use of Buvidal<sup>®</sup>{' '}
            Weekly;
          </li>
          <li>
            You will provide your patients with access to the <a href={PatientEducationBooklet} target='_blank' rel="noopener noreferrer">TGA-approved
            patient education material</a>, <a href={WeeklyCMI} target='_blank' rel="noopener noreferrer">Buvidal<sup>®</sup> Weekly Consumer Medicines
            Information (CMI)</a>, <a href={PatientAlertCard} target='_blank' rel="noopener noreferrer">a completed Patient Alert Card</a> AND obtain informed consent from your patients
            prior to enrolling them in the PFP;
          </li>
          <li>
          You understand that treatment can only be administered to a patient enrolled in the program;
          </li>
          <li>
            You understand that the registration process includes submitting an order for an Initiation Pack. When your registration is verified, your order will be processed and you will receive a Buvidal<sup>®</sup> Weekly Initiation Pack consisting of one <strong>16 mg</strong> and two <strong>8 mg</strong> doses;
          </li>
          <li>
            You understand that you can only order and have on hand one
            Initiation Pack at a time. Once a patient has been successfully
            enrolled into the program and allocated the pack on hand, a new
            order can be submitted;
          </li>
          <li>
            You understand that you may enrol a maximum of 10 patients in the
            PFP during the patient enrolment period. If there is a strong clinical need, increases may be considered on a case-by-case basis;
          </li>
          <li>
            You understand that the program is planned to conclude on 1 April
            2022 but can expire sooner or be extended at the discretion of
            Camurus;
          </li>
          <li>
            You understand that patients enrolled in the program can be
            allocated a treatment Initiation Pack, but that <strong>ongoing maintenance
            treatment must be prescribed via PBS/S100 script in accordance with
            standard clinical practice and that ongoing supply will not be
            provided via this program</strong>;
          </li>
          <li>
            You understand that if you choose to access the product via a
            pharmacy, Camurus are not responsible for, and have no control over,
            dispensing fees that may be charged by the pharmacy. These fees
            where applicable are the responsibility of the patient;
          </li>
          <li>
            You understand that if you are treating patients at a public
            hospital or clinic, it will be necessary to obtain approvals,
            including but not limited to Drug and Therapeutics Committee (DTC),
            to validate your registration and gain access to the program;
          </li>
          <li>
            You understand that the PFP is open only to patients requiring
            treatment initiation – either new to treatment, or returning to
            treatment, and who meet additional eligibility criteria (please
            refer to the <a href={HCPGuide} target='_blank' rel="noopener noreferrer">HCP Guide</a> for more information);
          </li>
          <li>
            You understand that Buvidal<sup>®</sup> cannot be dispensed directly to a patient and that if it is being supplied by a pharmacy, the arrangements for
            delivery must occur directly between the pharmacy and the clinic
            where the product is to be administered;
          </li>
          <li>
            You understand that on completion of the PFP, all remaining Buvidal<sup>®</sup>
            {' '}Weekly PFP supply should be disposed of, or returned to Camurus. You
            can contact the PFP Administrator to request pick up of unused
            supply at the conclusion of the program;
          </li>
          <li>
            You understand that you are required to report suspected adverse
            events and other safety findings including occupational exposure,
            off-label use, lack of efficacy, interactions, suspicion of
            counterfeit product, adverse reactions including those related to
            quality defect; suspected transmission of an infectious agent by a
            medicinal product, overdose, misuse, abuse, medication errors, loss
            of effect, pregnancy and lactation exposure, and any unexpected therapeutic benefit to Camurus Drug
            Safety by phone on 1800 142 038 or email <a href='mailto:safety@camurus.com'>safety@camurus.com</a>. Product
            complaints are to be reported to <a href='mailto:complaints@camurus.com'>complaints@camurus.com</a>. Please note
            that Camurus Drug Safety partners may need to contact you to follow
            up if clarification of the spontaneous report is required; and
          </li>
          <li>
            You understand that you are also encouraged to report any adverse
            events during the PFP directly to the Therapeutic Goods
            Administration (TGA) at <a href='https://www.tga.gov.au/reporting-problems' target='_blank' rel="noopener noreferrer">www.tga.gov.au/reporting-problems</a>.
          </li>
        </ul>
    </>
  );
};

export default TermsAndConditionsContent;

//polyfills
import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'core-js/fn/object/set-prototype-of';
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga'
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

import { ConnectedRouter } from 'connected-react-router'
import { history } from './modules';

import lastAction from 'utils/lastAction'
import moment from 'moment';

ReactGA.initialize('UA-138186555-1', {
  debug: process.env.NODE_ENV === 'development',
});

store.subscribe(() => {
  //console.log("store changes", store.getState())
  lastAction.set(moment());
})

const app = (
 <Provider store={store}>
  <ConnectedRouter history={history}>
    <App />
  </ConnectedRouter>
 </Provider>
);

ReactDOM.render(
 app,
 document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

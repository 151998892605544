import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import NavButton from "components/NavButton";

class Navigation extends Component {
  render() {
    return (
      <div className="navigation">
        <div className="secondary">
          {
            this.props.secondaryLink &&
              <NavLink to={this.props.secondaryLink}>{this.props.secondaryText}</NavLink>
          }
        </div>
        <div className="primary">
          <button type="submit" className="button-type-1">{this.props.primaryText}</button>
        </div>
      </div>
    );
  }
}

export default Navigation;
import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerBox from "view/accounts/components/ContainerBox";
import Navigation from "view/accounts/components/Navigation";
import { Field } from "components/Forms"
import { reduxForm, SubmissionError } from 'redux-form';
import { requestPatchUser } from 'modules/user/actions'


import {_required, _email} from "utils/validation"
let required = _required();
let email = _email();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class ChangeEmail extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {
      this.props.requestPatchUser({
        data: results.user,
        callback: (result)=>{
          if (!result.error) {
            this.props.history.push('/my-account/change-email-confirm');
            return resolve();
          } else {
            return reject(new SubmissionError({
              ...result.validation
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="my-account account-change-email" title="Change email" noLinks isLoading={this.props.submitting}>
        <p>To change your email please enter your email.</p>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <Field name="user.email" type="email" title="Your new email address" autoFocus validate={[required, email]} />
          <Navigation secondaryText="Back" secondaryLink="/my-account" primaryText="Next" primaryLink="/change-email-confirm" />
        </form>

      </ContainerBox>
    )
  }
}

ChangeEmail = reduxForm({
  form: 'ChangeEmail' // a unique name for this form
})(ChangeEmail);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import getQueryParams from "utils/getQueryParams";

import Step1 from "./product/onboarding/Step1";
import Step2 from "./product/onboarding/Step2";
import Step3 from "./product/onboarding/Step3";
import Step4 from "./product/onboarding/Step4";
import Step5 from "./product/onboarding/Step5";
import Step6 from "./product/onboarding/Step6";
import SignupThanks from "./accounts/SignupThanks";

import About from "./company/About";
import PrivacyStatement from "./company/PrivacyStatement";
import TermsOfUse from "./company/TermsOfUse";
import Profile from "./company/Profile";
import IncorrectSpecialisation from "./company/IncorrectSpecialisation";

import ChangeMobile from "./company/accounts/ChangeMobile";
import ChangeEmail from "./company/accounts/ChangeEmail";
import ChangePassword from "./company/accounts/ChangePassword";
import ConfirmEmail from "./company/accounts/ConfirmEmail";
import ConfirmMobile from "./company/accounts/ConfirmMobile";
import ProfileUpdated from "./company/accounts/ProfileUpdated";
import TooManyPinAttempts from "./accounts/TooManyPinAttempts";
import TooManySMSAttempts from "./accounts/TooManySMSAttempts";


import Patients from "./product/dashboard/Patients";
import PatientsNotOpen from "./product/dashboard/PatientsNotOpen";
import PatientsPendingValidation from "./product/dashboard/PatientsPendingValidation";
import Resources from "./product/dashboard/Resources";
import Settings from "./product/dashboard/Settings";
import ContactUs from "./product/dashboard/ContactUs";
import ProductTermsAndConditions from "./product/dashboard/ProductTermsAndConditions";
import ProductPrivacyStatement from "./product/dashboard/ProductPrivacyStatement";

import PatientResources from "./product/dashboard/PatientResources";

import DeRegisterProfile from "./product/dashboard/DeRegisterProfile";

import OnboardingPatientEnrol from "./product/dashboard/patients/OnboardingPatientEnrol";
import OnboardingDrugOrder from "./product/dashboard/patients/OnboardingDrugOrder";
import OnboardingConfirmOrder from "./product/dashboard/patients/OnboardingConfirmOrder";

import ModifyOrder from "./product/dashboard/patients/ModifyOrder";
import PatientConfirmOrder from "./product/dashboard/patients/PatientConfirmOrder";
import PatientDetails from "./product/dashboard/patients/PatientDetails";
import OrderExtra from "./product/dashboard/patients/OrderExtra";
import OrderExtraConfirm from "./product/dashboard/patients/OrderExtraConfirm";
import OrderExtraOutStock from "./product/dashboard/patients/OrderExtraOutStock";
import DiscontinuePatient from "./product/dashboard/patients/DiscontinuePatient";
import PageNotFound from './misc/PageNotFound';
import hasBuvidalAccess from 'utils/hasBuvidalAccess';
import AdverseEventPatient from './product/dashboard/patients/AdverseEventPatient';
import DidNotProceedPatient from './product/dashboard/patients/DidNotProceedPatient';

import Summary from './product/dashboard/Summary';
class Routes extends Component {
  render() {
    return (
      <Switch>
        {/* My Profile */}
        <Route exact path="/my-account" render={(props) => <Profile {...props} />} />
        <Route exact path="/my-account/change-mobile" render={(props) => <ChangeMobile {...props} />} />
        <Route exact path="/my-account/change-mobile-confirm" render={(props) => <ConfirmMobile {...props} />} />
        <Route exact path="/my-account/change-email" render={(props) => <ChangeEmail {...props} />} />
        <Route exact path="/my-account/change-email-confirm" render={(props) => <ConfirmEmail {...props} />} />
        <Route exact path="/my-account/change-password" render={(props) => <ChangePassword {...props} />} />
        <Route exact path="/my-account/profile-updated" render={(props) => <ProfileUpdated {...props} />} />
        <Route exact path="/my-account/too-many-pin-attempts" render={(props) => <TooManyPinAttempts {...props} />} />
        <Route exact path="/my-account/too-many-sms-attempts" render={(props) => <TooManySMSAttempts {...props} />} />

        {/* Company Start */}
        <Route exact path="/company/about" render={(props) => <About {...props} />} />
        <Route exact path="/company/privacy" render={(props) => <PrivacyStatement {...props} />} />
        <Route exact path="/company/terms" render={(props) => <TermsOfUse {...props} />} />


        {!hasBuvidalAccess(this.props.user) && [
          <Route exact path="/unmatched-specialisation" render={(props) => <IncorrectSpecialisation type="incorrect-specialisation" {...props} />} />,
          <Redirect to="/unmatched-specialisation" />]
        }

        <Redirect exact from="/unmatched-specialisation" to="/" />

        <Route exact path="/signup-thanks" render={(props) => <SignupThanks {...props} />} />
        <Route exact path="/buvidal/setup/step-1" render={(props) => <Step1 {...props} />} />
        <Route exact path="/buvidal/setup/step-2" render={(props) => <Step2 {...props} />} />
        <Route exact path="/buvidal/setup/step-3" render={(props) => <Step3 {...props} />} />
        <Route exact path="/buvidal/setup/step-4" render={(props) => <Step4 {...props} />} />
        <Route exact path="/buvidal/setup/step-5" render={(props) => <Step5 {...props} />} />
        <Route exact path="/buvidal/setup/step-6" render={(props) => <Step6 {...props} />} />

        {this.props.onboardingStep < 6 &&
          <Redirect to={"/buvidal/setup/step-" + this.props.onboardingStep} />
        }
        <Route exact path="/buvidal/setup/step-6" render={(props) => <Step6 {...props} />} />
        {this.props.onboardingStep < 7 &&
          <Redirect to={"/buvidal/setup/step-" + this.props.onboardingStep} />
        }

        {/* Misc */}
        <Route exact path="/buvidal-patient-resources" render={(props) => <PatientResources type="patient" {...props} />} />


        {/* Dashboard */}
        <Route path="/buvidal/resources" render={(props) => <Resources type="resources" {...props} />} />
        <Route exact path="/buvidal/settings" render={(props) => <Settings type="settings" {...props} />} />
        <Route path="/buvidal/contact-us" render={(props) => <ContactUs type="contact-us" {...props} />} />
        <Route path="/buvidal/terms-and-conditions" render={(props) => <ProductTermsAndConditions type="product-terms-conditions" {...props} />} />
        <Route path="/buvidal/summary" render={(props) => <Summary type="summary" {...props} />} />
        <Route path="/buvidal/privacy-statement" render={(props) => <ProductPrivacyStatement type="product-privacy" {...props} />} />
        <Route path="/buvidal/settings/deregister-profile" render={(props) => <DeRegisterProfile type="de-register-profile" {...props} />} />

        <Route exact path="/buvidal/patients" render={(props) => <Patients type="patient" {...props} />} />

        <Redirect exact from="/" to="/buvidal/patients" />
        <Redirect exact from="/undefined" to="/buvidal/patients" />

        {
          (!this.props.user.buvidal || !this.props.user.buvidal.isValidated) &&
            <Route render={(props) => <PageNotFound {...props} />} />
        }

        
        <Route path="/buvidal/patients/modify-order" render={(props) => <ModifyOrder type="modify-order" {...props} />} />
        <Route path="/buvidal/patients/confirm-order" render={(props) => <PatientConfirmOrder type="confirm-order" {...props} />} />
        <Route path="/buvidal/patients/patient-details" render={(props) => <PatientDetails type="patient-details" {...props} />} />
        <Route path="/buvidal/patients/patient-did-not-proceed" render={(props) => <DidNotProceedPatient type="discontinue-patient" {...props} />} />
        <Route path="/buvidal/patients/patient-adverse-event" render={(props) => <AdverseEventPatient type="discontinue-patient" {...props} />} />
        <Route path="/buvidal/patients/order-extra" render={(props) => <OrderExtra type="order-extra" {...props} />} />
        <Route path="/buvidal/patients/order-extra-confirm" render={(props) => <OrderExtraConfirm type="order-extra-confirm" {...props} />} />
        <Route path="/buvidal/patients/order-extra-out-of-stock" render={(props) => <OrderExtraOutStock type="extra-out-of-stock" {...props} />} />
        <Route path="/buvidal/patients/discontinue-patient" render={(props) => <DiscontinuePatient type="discontinue-patient" {...props} />} />


        <Route path="/buvidal/patients/onboarding-enrol-patient" render={(props) => <OnboardingPatientEnrol type="onboarding-enrol-patient" {...props} />} />
        <Route path="/buvidal/patients/onboarding-drug-order" render={(props) => <OnboardingDrugOrder type="onboarding-drug-order" {...props} />} />
        <Route path="/buvidal/patients/onboarding-confirm-order" render={(props) => <OnboardingConfirmOrder type="onboarding-confirm-order" {...props} />} />


        {/* <Route exact path="/buvidal/patients" render={(props) => <PatientsNotOpen type="patient" {...props} /> } /> */}
        {/* <Route exact path="/buvidal/patients" render={(props) => <PatientsPendingValidation type="patient" {...props} /> } /> */}

        <Route render={(props) => <PageNotFound {...props} />} />

      </Switch>
    )
  }
};

export default withRouter(props => <Routes {...props} />);
import React from "react";

const PrivacyStatementContent = () => {
  return (
    <>
      <h1>Privacy Statement</h1>
      <p>
        Camurus Pty Ltd has engaged N of One Solutions (PFP Administrator) for
        the purposes of administering the Buvidal<sup>®</sup> Weekly Direct
        Initiation PFP. Your personal information is collected by the PFP
        Administrator in order to facilitate your participation in the program
        and for the PFP Administrator to fulfill their obligations under the
        PFP. Your personal information will be disclosed to Camurus and to
        third-party services providers (e.g. logistics providers involved with
        drug deliveries) who assist the PFP Administrator and Camurus in
        administering the PFP. Your information may also be provided to local
        and state health authorities to confirm your eligibility to participate
        in this program.
      </p>
      <p>
        Your personal information will be treated as confidential and will be
        securely stored at all times. The PFP Administrator is accountable for
        all personal information it discloses and will take appropriate measures
        to ensure that the confidentiality of your information is safeguarded.
      </p>
      <p>
        If you would like more information about the PFP Administrator privacy
        policy and procedures, or if you would like to access or update
        information about you that the PFP Administrator holds, or to make a
        privacy complaint, please contact N of One Solutions via email:{" "}
        <a href="mailto:admin@nof1solutions.com.au">
          admin@nof1solutions.com.au
        </a>{" "}
        or phone <a href="tel:+61 2 4214 8010">+61 2 4214 8010</a>.
      </p>
      <p>
        For more information on privacy at Camurus you can{" "}
        <a
          href="https://buvidal.com.au/privacy-notice/"
          target="_blank"
          rel="noopener noreferrer"
        >
          click here
        </a>
        . To access or correct your personal information, or make a privacy
        complaint, please email:{" "}
        <a href="mailto:australia@camurus.com">australia@camurus.com</a> or
        phone: <a href="tel:1800 142 038">1800 142 038</a>.
      </p>
    </>
  );
};

export default PrivacyStatementContent;

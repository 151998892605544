import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { NavLink } from "react-router-dom";
import NavButton from "components/NavButton";

import { requestLogout } from 'modules/user/actions';
import hasBuvidalAccess from 'utils/hasBuvidalAccess';

function mapStateToProps(state) {
  return {
    user: state.user,
    router: state.router
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestLogout,
  }, dispatch);
}

class Header extends Component {

  componentDidMount() {
    const mq = window.matchMedia("(max-width: 640px)");
    let that = this;

    window.$(window).resize(function () {
      if (mq.matches) {
        that.toggleOffAccountMenu();
      } else {
        that.closeMenu();
      }
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      menuState: false,
      accountMenuState: false,
    };
  }

  logout() {
    this.props.requestLogout(() => {
    });
  }

  toggleMenu = () => {
    if (!this.state.menuState) {
      window.$(".bottom-mandatories").addClass("menu-active");
    } else {
      window.$(".bottom-mandatories").removeClass("menu-active");
    }

    this.setState((prevState) => { return { ...prevState, menuState: !prevState.menuState } });
  }

  closeMenu = () => {
    window.$(".bottom-mandatories").removeClass("menu-active");
    this.setState((prevState) => { return { ...prevState, menuState: false } });
  }

  toggleAccountMenu = () => {
    this.setState((prevState) => { return { ...prevState, accountMenuState: !prevState.accountMenuState } });
  }

  toggleOffAccountMenu = () => {
    this.setState((prevState) => { return { ...prevState, accountMenuState: false } });
  }

  headerType(page) {
    let urlArray = page.split('/');
    let pagetype = urlArray[1];

    if (pagetype.includes("company") || pagetype.includes("my-account") || page.includes("specialisation") || pagetype.includes("signup-thanks")) {
      return <img className="logo medax" src={require('../img/medax/medax_logo.png')} alt="medax-logo" />;
    } else {
      return <img className="logo" src={require('../img/product/buvidal_logo.svg')} alt="buvidal-logo" />;
    }
  }

  rightHeaderType(page) {
    let urlArray = page.split('/');
    let pagetype = urlArray[1];

    if (pagetype.includes("company") || pagetype.includes("my-account")) {
      return " medax-type";
    } else {
      return "";
    }
  }

  render() {
    let firstName = this.props.user.firstName || "";
    let lastName = this.props.user.lastName || "";

    if (this.props.type === "authenticated") {
      return (
        <header className={"authenticated" + ((this.state.menuState) ? " active" : "") + (this.rightHeaderType(this.props.router.location.pathname))}>
          <div className="header-wrapper">
            <div className="left">
              <NavLink onClick={() => this.closeMenu()} to="/">{this.headerType(this.props.router.location.pathname)}</NavLink>
            </div>
            <div className="right">
              {hasBuvidalAccess(this.props.user) &&
                <NavButton onClick={() => this.closeMenu()} to="/" className="button-type-2">Back to Buvidal PFP</NavButton>
              }

              <div className="account" onClick={() => this.toggleAccountMenu()}>
                <div className="account-profile">{firstName.toString().charAt(0).toUpperCase() + lastName.toString().charAt(0).toUpperCase()}</div>
              </div>
            </div>

            <div className="burger" onClick={() => this.toggleMenu()}></div>
            <div className="mobile-menu" style={
              {
                opacity: ((this.state.menuState) ? "1" : "0"),
                pointerEvents: ((this.state.menuState) ? "all" : "none"),
                // visibility: ((this.state.menuState)?"visible":"hidden"),
              }
            }>

              {(!this.props.user.buvidal || this.props.user.buvidal.onboardingStep || this.props.user.buvidal.onboardingStep < 6) ?
                <div className="primary">
                  <ul>
                    <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/buvidal/patients">Patients</NavLink></li>
                    <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/buvidal/resources">Resources</NavLink></li>
                    <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/buvidal/settings">Settings</NavLink></li>
                    <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/buvidal/contact-us">Contact us</NavLink></li>
                    <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/buvidal/terms-and-conditions">Terms & conditions</NavLink></li>
                    <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/buvidal/privacy-statement">Privacy statement</NavLink></li>
                  </ul>
                </div>
                :
                (hasBuvidalAccess(this.props.user))  &&
                <div className="button-navigation full-width">
                    <NavButton onClick={() => this.toggleMenu()} to="/" className="button-type-2">Back to Buvidal PFP</NavButton>
                </div>
              }

              <hr />
              <div className="secondary">
                <div className="top">
                  <div className="company">
                    <img className="logo" src={require('../img/medax/medax_logo.png')} alt="medax-logo" />
                  </div>
                  <div className="account">
                    <div className="account-profile">{firstName.toString().charAt(0).toUpperCase() + lastName.toString().charAt(0).toUpperCase()}</div>
                  </div>
                </div>
                <ul>
                  <li><NavLink onClick={() => this.toggleMenu()} to="/my-account/">My Account</NavLink></li>
                  <li><NavLink onClick={() => this.toggleMenu()} to="/company/about/">About MedAX</NavLink></li>
                  <li><NavLink onClick={() => this.toggleMenu()} to="/company/privacy/">Privacy statement</NavLink></li>
                  <li><NavLink onClick={() => this.toggleMenu()} to="/company/terms/">Terms of use</NavLink></li>
                  <li><a onClick={() => this.logout()}>Log out</a></li>
                </ul>
              </div>
            </div>

          </div>
          <div className="account-menu" onMouseLeave={() => this.toggleOffAccountMenu()} style={
            {
              opacity: ((this.state.accountMenuState) ? "1" : "0"),
              pointerEvents: ((this.state.accountMenuState) ? "all" : "none"),
              // visibility: ((this.state.accountMenuState)?"visible":"hidden"),
            }
          }>
            <img className="logo" src={require('../img/medax/medax_logo.png')} alt="medax-logo" />

            <ul>
              <li><NavLink to="/my-account/">My Account</NavLink></li>
              <li><NavLink to="/company/about/">About MedAX</NavLink></li>
              <li><NavLink to="/company/privacy/">Privacy statement</NavLink></li>
              <li><NavLink to="/company/terms/">Terms of use</NavLink></li>
              <li><a onClick={() => this.logout()}>Log out</a></li>
            </ul>
          </div>
        </header>
      )
    }
    else {
      return (
        <header className="sign-out">
          <div className="header-wrapper">
            <div className="left">
              <NavLink onClick={() => this.closeMenu()} to="/"><img className="logo medax" src={require('../img/medax/medax_logo.png')} alt="medax-logo" /></NavLink>
            </div>
            <div className="right">
              <NavButton to="/login" className="button-type-2">Log In</NavButton>
              <NavButton to="/signup" className="button-type-1">Register</NavButton>
            </div>

            <div className="burger" onClick={() => this.toggleMenu()}></div>
            <div className="mobile-menu" style={
              {
                opacity: ((this.state.menuState) ? "1" : "0"),
                pointerEvents: ((this.state.menuState) ? "all" : "none"),
                visibility: ((this.state.menuState) ? "visible" : "hidden"),
              }
            }>
              <div className="button-navigation">
                <NavButton onClick={() => this.toggleMenu()} to="/login" className="button-type-2">Log In</NavButton>
                <NavButton onClick={() => this.toggleMenu()} to="/signup" className="button-type-1">Register</NavButton>
              </div>
              <div className="primary">
              </div>
              <div className="secondary">
                <ul>
                  <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/company/about">About MedAX</NavLink></li>
                  <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/company/privacy">Privacy Statement</NavLink></li>
                  <li><NavLink activeClassName="current" onClick={() => this.toggleMenu()} to="/company/terms">Terms of Use</NavLink></li>
                </ul>
              </div>
            </div>

          </div>
        </header>
      )

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);